<template>
  <BaseModal :showing="showing" @close="closeModal">
    <template #header>
      <div class="flex items-center text-lg font-semibold">
        <h1>Add a New Family Member</h1>
      </div>
    </template>
    <template #body>
      <VeeForm
        ref="addPersonForm"
        :validation-schema="validationSchema"
        @submit="onSubmit"
        class="w-full space-y-4 md:space-y-6 mb-6">
        <AppNotification variant="danger" v-if="submittingError">
          <template #icon>
            <DocumentDuplicateIcon class="flex-shrink-0 w-5 h-5" />
          </template>
          <template #message>
            {{ submittingError }}
          </template>
        </AppNotification>
        <div class="grid gap-6 grid-cols-2">
          <div>
            <span
              for="firstName"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              First name
            </span>
            <Field name="firstName" type="text" v-slot="{ field, errors }">
              <TextInput
                v-bind="field"
                type="text"
                :failed="!!errors.length"
                placeholder="Barney" />
            </Field>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="firstName" />
          </div>
          <div>
            <span
              for="lastName"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Last name
            </span>
            <Field name="lastName" type="text" v-slot="{ field, errors }">
              <TextInput
                v-bind="field"
                type="text"
                :failed="!!errors.length"
                placeholder="Fife" />
            </Field>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="lastName" />
          </div>
        </div>
        <div class="grid gap-6 grid-cols-2">
          <div>
            <label
              for="city"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              City
            </label>
            <Field name="city" type="text" v-slot="{ field, errors }">
              <PlaceAutocomplete
                @keydown.enter.prevent
                v-bind="field"
                id="addPersonCity"
                autocomplete-id="addPersonCity"
                placeholder="Los Angeles, CA"
                :value="displayLocationInput"
                @localityChanged="localityChanged"
                :failed="!!errors.length" />
            </Field>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="city" />
          </div>
          <div>
            <label
              for="birthdate"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Birthdate
            </label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <Field name="birthdate" type="text" v-slot="{ field, errors }">
                <DateInput
                  v-bind="field"
                  :failed="!!errors.length"
                  name="birthdate"
                  id="birthdate" />
              </Field>
            </div>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="birthdate" />
          </div>
        </div>
        <div class="grid gap-6 md:grid-cols-2">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Email
              <span class="text-gray-400 font-normal">(Optional)</span>
            </label>
            <Field name="email" type="text" v-slot="{ field, errors }">
              <TextInput
                v-bind="field"
                :failed="!!errors.length"
                type="email"
                name="email"
                id="email"
                placeholder="barney@mayberry.com" />
            </Field>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="email" />
          </div>
          <div>
            <label
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Phone Number
              <span class="text-gray-400 font-normal">(Optional)</span>
            </label>
            <Field name="phone" type="text" v-slot="{ field, errors }">
              <PhoneInput
                :failed="!!errors.length"
                v-bind="field"
                name="phone"
                id="phone" />
            </Field>
            <ErrorMessage
              class="mt-2 text-sm text-red-600 dark:text-red-500"
              name="phone" />
          </div>
        </div>
      </VeeForm>
    </template>
    <template #footer>
      <div
        class="flex items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-600">
        <SubmitButton
          variant="success"
          @click="addPerson"
          :loading="addPersonStatus == 'loading'"
          :failed="addPersonStatus == 'failed'"
          :success="addPersonStatus == 'completed'"
          class="w-32 h-10">
          Add Person
        </SubmitButton>
        <SubmitButton @click="closeModal">Close</SubmitButton>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import * as yup from 'yup';
import { defineComponent, ref } from 'vue';
import { useUserStore } from '@/store/user.store';
import { Field, ErrorMessage, Form as VeeForm } from 'vee-validate';
import { AddFamilyMemberForm } from '@/models/user.model';
import { getSubmit } from '@/utils/form-helpers';
import BaseModal from '@/components/modals/BaseModal.vue';
import PlaceAutocomplete from '@/components/forms/PlaceAutocomplete.vue';
import DateInput from '@/components/forms/DateInput.vue';
import TextInput from '@/components/forms/TextInput.vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import SubmitButton from '@/components/forms/SubmitButton.vue';
import AppNotification from '../common/AppNotification.vue';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/solid';

interface LocalityDict {
  city: string;
  state: string;
}

export default defineComponent({
  name: 'AddPersonModal',
  props: {
    showing: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseModal,
    VeeForm,
    Field,
    TextInput,
    DateInput,
    PhoneInput,
    SubmitButton,
    PlaceAutocomplete,
    ErrorMessage,
    AppNotification,
    DocumentDuplicateIcon,
  },
  emits: ['close', 'createdFamilyMember'],
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
  setup(props, { emit }) {
    const userStore = useUserStore();
    const addPersonForm = ref();
    const cityInput = ref('');
    const stateInput = ref('');
    const addPersonStatus = ref('');
    const submittingError = ref('');
    const displayLocationInput = ref('');

    const validationSchema = yup.object({
      firstName: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Only letters are allowed')
        .required()
        .label('First name'),
      lastName: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Only letters are allowed')
        .required()
        .label('Last name'),
      city: yup.string().required().label('City'),
      email: yup.string().email().notRequired().label('Email'),
      birthdate: yup
        .string()
        .required()
        .matches(
          /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
          'Date format must be mm/dd/yyyy',
        )
        .test('age', 'Must be at least 18 years old', (value) => {
          if (!value) return false;

          // Convert birthdate string to a Date object
          const birthdate = new Date(value);
          const currentDate = new Date();

          // Calculate the age difference in years
          const age = currentDate.getFullYear() - birthdate.getFullYear();
          if (
            currentDate.getMonth() < birthdate.getMonth() ||
            (currentDate.getMonth() === birthdate.getMonth() &&
              currentDate.getDate() < birthdate.getDate())
          ) {
            return age - 1 >= 18; // Adjust age calculation if birthday hasn't occurred yet this year
          }

          return age >= 18;
        })
        .label('Birthdate'),
      phone: yup
        .string()
        .notRequired()
        .test('phone-number', 'Invalid phone number', (value) => {
          if (!value) return true; // Allow empty value
          const numericValue = value.replace(/\D/g, ''); // Extract numeric characters
          return numericValue.length === 0 || numericValue.length === 10;
        })
        .label('Phone'),
    });

    const addPerson = async () => {
      const { valid } = await addPersonForm.value.validate();
      addPersonStatus.value = 'loading';
      if (valid) {
        addPersonForm.value.$el.requestSubmit();
      } else {
        addPersonStatus.value = 'failed';
        await new Promise((r) => setTimeout(r, 1000));
        addPersonStatus.value = '';
      }
    };

    const localityChanged = (dict: LocalityDict) => {
      const { city, state } = dict;
      cityInput.value = city;
      stateInput.value = state;
      displayLocationInput.value = `${city}, ${state}`;
    };

    const onSubmit = getSubmit(validationSchema, async (values) => {
      const data: AddFamilyMemberForm = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        city: cityInput.value,
        state: stateInput.value,
        birthdate: values.birthdate,
        phone: values.phone,
      };
      try {
        const newFamilyMember = await userStore.addFamilyMember(data);
        emit('createdFamilyMember', {
          familyMemberId: newFamilyMember.id,
          firstName: newFamilyMember.firstName,
          lastName: newFamilyMember.lastName,
        });
        emit('close');
      } catch (error: any) {
        if (error.response) {
          submittingError.value = error.response.data.message;
        }
      }
      addPersonStatus.value = 'complete';
    });

    const defaultValues = {
      firstName: 'Barney',
      lastName: 'Fife',
      city: 'Mayberry',
      state: 'NC',
      birthdate: '07/03/1998',
      email: 'barney@mayberry.com',
      phone: '8312343007',
    };

    return {
      displayLocationInput,
      addPersonStatus,
      addPersonForm,
      validationSchema,
      defaultValues,
      submittingError,
      addPerson,
      onSubmit,
      localityChanged,
    };
  },
});
</script>
