import apiClient from '@/utils/http-common';
import { defineStore } from 'pinia';
import { useUserStore } from './user.store';
import { RegisterForm, LoginForm } from '@/models/user.model';
import router from '@/router';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    // user: JSON.parse(localStorage.getItem('user') || 'false'),
    access_token: JSON.parse(localStorage.getItem('access_token') || 'false'),
    refresh_token: JSON.parse(localStorage.getItem('refresh_token') || 'false'),
  }),
  actions: {
    async register(data: RegisterForm, recaptchaPayload: string) {
      await apiClient.post('/auth/register', data, {
        headers: { recaptcha: recaptchaPayload },
      });
    },
    async login(data: LoginForm) {
      const loginResponse = await apiClient.post(`auth/login`, data);
      const userStore = await useUserStore();

      // update pinia state
      this.access_token = loginResponse.data.access_token;
      this.refresh_token = loginResponse.data.refresh_token;

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('access_token', JSON.stringify(this.access_token));
      localStorage.setItem('refresh_token', JSON.stringify(this.refresh_token));

      await userStore.getDetails();
    },
    async refreshTokens() {
      try {
        const response = await apiClient.post(
          'auth/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${this.refresh_token}`,
            },
          },
        );
        this.access_token = response.data.access_token;
        this.refresh_token = response.data.refresh_token;
        localStorage.setItem(
          'access_token',
          JSON.stringify(response.data.access_token),
        );
        localStorage.setItem(
          'refresh_token',
          JSON.stringify(response.data.refresh_token),
        );
      } catch (e) {
        await this.logout();
        router.push('login');
      }
    },
    async requestCode() {
      await apiClient.post('/account/email/verify', { verify: true });
    },
    async validateCode(token: string) {
      await apiClient.post('/account/email/verify/token', { token });
    },
    logout() {
      this.access_token = null;
      this.refresh_token = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
    },
  },
});
