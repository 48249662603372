import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "block truncate" }
const _hoisted_4 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronUpDownIcon = _resolveComponent("ChevronUpDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_PlusCircleIcon = _resolveComponent("PlusCircleIcon")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_ctx.selectedPerson && (_ctx.showAddFamilyMember || _ctx.familyMembers.length > 1))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Listbox, {
          modelValue: _ctx.selectedPerson,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPerson) = $event)),
            _cache[2] || (_cache[2] = (value) => _ctx.$emit('selected-person', value.id))
          ]
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ListboxButton, { class: "relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-200 rounded-lg shadow-sm focus:outline-none hover:shadow focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedPerson.firstName) + " " + _toDisplayString(_ctx.selectedPerson.lastName), 1),
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_ChevronUpDownIcon, {
                      class: "h-5 w-5 text-gray-400",
                      "aria-hidden": "true"
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_Transition, {
                "leave-active-class": "transition duration-100 ease-in",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ListboxOptions, { class: "absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-10 py-1 text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.familyMembers, (person) => {
                        return (_openBlock(), _createBlock(_component_ListboxOption, {
                          key: person.id,
                          value: person,
                          as: "template"
                        }, {
                          default: _withCtx(({ active, selected }) => [
                            _createElementVNode("li", {
                              class: _normalizeClass([
                  active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                  'relative cursor-pointer select-none py-2 pl-10 pr-4',
                ])
                            }, [
                              _createElementVNode("span", {
                                class: _normalizeClass([
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ])
                              }, _toDisplayString(person.firstName) + " " + _toDisplayString(person.lastName), 3),
                              selected
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                    _createVNode(_component_CheckIcon, {
                                      class: "h-5 w-5",
                                      "aria-hidden": "true"
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128)),
                      (_ctx.showAddFamilyMember)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                            _createElementVNode("a", {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addFamilyMember'))),
                              class: "group flex items-center px-4 py-2 cursor-pointer"
                            }, [
                              _createVNode(_component_PlusCircleIcon, {
                                class: "mr-3 h-5 w-5 text-blue-700 group-hover:text-blue-800",
                                "aria-hidden": "true"
                              }),
                              _createTextVNode(" Add family member ")
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}