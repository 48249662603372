import apiClient from '@/utils/http-common';
import { defineStore } from 'pinia';

export const useReportStore = defineStore({
  id: 'report',
  state: () => ({}),
  actions: {
    async getReport(reportId: string) {
      const response = await apiClient.get(`/account/report/${reportId}`);
      return response.data;
    },
  },
});
