<template>
  <div class="mx-auto mb-4">
    <div class="bg-white border border-gray-200 rounded-lg shadow-sm">
      <!-- Card header -->
      <header class="text-center px-5 pt-9 max-w-[500px] m-auto">
        <!-- Card name -->
        <template v-if="!userStore.previewPlan.planName && defaultPlan">
          <h3 class="text-gray-700 text-xl mb-2">
            Get PrivacyZen
            {{ defaultPlan.planName }}
            for
            <b>${{ defaultPlan.cost }}/month</b>
          </h3>
        </template>
        <template v-else>
          <h3 class="text-gray-700 text-xl mb-2">
            Get PrivacyZen
            {{ userStore.previewPlan.planName }}
            for
            <b>
              ${{ userStore.previewPlan.cost }}/{{
                userStore.previewPlan.interval
              }}
            </b>
          </h3>
        </template>
        <div class="text-md text-gray-500">
          We take care of everything: automatic opt-outs from 50+ data brokers
          and vigilant 7-day scans to ensure your data stays removed.
        </div>
      </header>
      <!-- Card body -->
      <div class="text-center px-5 py-6">
        <form id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <!-- :disabled="!stripe || loading" -->
          <SubmitButton
            type="submit"
            @click="handleSubmit"
            :loading="paymentStatus == 'loading'"
            :success="paymentStatus == 'success'"
            :failed="paymentStatus == 'failed'"
            class="w-full mt-4 h-10">
            Subscribe
          </SubmitButton>
          <p class="mt-2 text-sm text-red-600 dark:text-red-500">
            {{ paymentError }}
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useStripeStore } from '@/store/stripe.store';
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeElementsOptionsMode,
} from '@stripe/stripe-js';
import SubmitButton from '@/components/forms/SubmitButton.vue';
import { useUserStore } from '@/store/user.store';

export default defineComponent({
  name: 'SubscribeForm',
  components: {
    SubmitButton,
  },
  setup() {
    const paymentStatus = ref('');
    const userStore = useUserStore();
    const stripeStore = useStripeStore();
    const stripe = ref<Stripe | null>(null);
    const elements = ref<StripeElements>({} as StripeElements);
    const paymentError = ref('');
    const defaultPlan = ref<any>(null);

    onMounted(async () => {
      const availablePlans = await userStore.getAvailablePlans();
      defaultPlan.value = availablePlans.monthly[0];
      const { publishableKey } = await stripeStore.getConfig();
      stripe.value = await loadStripe(publishableKey);

      if (stripe.value) {
        const options: StripeElementsOptionsMode = {
          mode: 'subscription',
          amount: 12 * 100,
          currency: 'usd',
          // Fully customizable with appearance API.
          appearance: {},
        };

        // Set up Stripe.js and Elements to use in checkout form
        elements.value = stripe.value.elements(options);

        // Create and mount the Payment Element
        const paymentElement = elements.value.create('payment');
        if (document.getElementById('payment-element')) {
          paymentElement.mount('#payment-element');
        }
      }
    });

    const subscribeFailed = async () => {
      await new Promise((r) => setTimeout(r, 1000));
      paymentStatus.value = 'failed';
      await new Promise((r) => setTimeout(r, 1000));
      paymentStatus.value = '';
    };

    const subscribeSuccess = async () => {
      await new Promise((r) => setTimeout(r, 1000));
      paymentStatus.value = 'success';
      await new Promise((r) => setTimeout(r, 1000));
      paymentStatus.value = '';
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      if (!stripe.value) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      paymentStatus.value = 'loading';

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.value.submit();
      if (submitError) {
        await subscribeFailed();
        return;
      }

      try {
        const planName =
          userStore.previewPlan.planName || defaultPlan.value.planName;
        const interval =
          userStore.previewPlan.interval || defaultPlan.value.interval;

        const { clientSecret } = await stripeStore.createSubscription({
          planName,
          interval,
        });
        const { error } = await stripe.value.confirmPayment({
          elements: elements.value,
          // redirect: 'always',
          redirect: 'if_required',
          clientSecret,
          confirmParams: {
            return_url: `${process.env.VUE_APP_API_URL}/payment-success`,
          },
        });
        if (error) {
          if (
            error.type === 'card_error' ||
            error.type === 'validation_error'
          ) {
            paymentError.value = error.message as string;
            await subscribeFailed();
          } else {
            paymentError.value =
              'An unknown error has occured when confirming the payment';
            await subscribeFailed();
          }
        } else {
          await userStore.pollSubscription();
          await subscribeSuccess();
        }
      } catch (error) {
        paymentError.value = 'An unknown error has occured';
        await subscribeFailed();
      }
      paymentStatus.value = '';
    };

    return {
      paymentStatus,
      userStore,
      defaultPlan,
      paymentError,
      handleSubmit,
      subscribeSuccess,
      subscribeFailed,
    };
  },
});
</script>
