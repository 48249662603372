import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createBlock(_component_TextInput, {
    type: "text",
    class: _normalizeClass({
      'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
        _ctx.failed,
    }),
    "data-maska": "(###) ###-####",
    placeholder: "(505) 555-0193"
  }, null, 8, ["class"])), [
    [_directive_maska]
  ])
}