<template>
  <div
    ref="otpInput"
    class="flex flex-row items-center space-x-2 mx-auto w-full">
    <input
      type="text"
      @keydown="handleKeyDown($event, ind)"
      v-for="(el, ind) in digits"
      :key="el! + ind"
      v-model="digits[ind]"
      :autofocus="ind === 0"
      :placeholder="String(ind + 1)"
      :class="{ bounce: digits[ind] !== null }"
      maxlength="1"
      class="w-10 h-10 text-center flex flex-col items-center justify-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue';

const props = defineProps<{
  digitCount: number;
  default?: string;
}>();

const digits = reactive<Array<string | null>>([]);

// Initialize digits based on default prop
if (props.default && props.default.length === props.digitCount) {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = props.default.charAt(i);
  }
} else {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = null;
  }
}

const otpInput = ref<HTMLFormElement | null>(null);
const emit = defineEmits(['update:otp']);

const isDigitsFull = function (): boolean {
  return digits.every((elem) => elem !== null && elem !== undefined);
};

const handleKeyDown = function (event: KeyboardEvent, index: number) {
  if (
    event.key !== 'Tab' &&
    event.key !== 'ArrowRight' &&
    event.key !== 'ArrowLeft'
  ) {
    event.preventDefault();
  }

  if (event.key === 'Backspace') {
    digits[index] = null;

    if (index !== 0 && otpInput.value?.children[index - 1]) {
      (otpInput.value.children[index - 1] as HTMLElement).focus();
    }

    return;
  }

  if (new RegExp('^([0-9])$').test(event.key)) {
    digits[index] = event.key;

    if (index !== props.digitCount - 1 && otpInput.value?.children[index + 1]) {
      (otpInput.value.children[index + 1] as HTMLElement).focus();
    }
  }

  if (isDigitsFull()) {
    emit('update:otp', digits.join(''));
  }
};
</script>

<style scoped>
.bounce {
  animation: pulse 0.3s ease-in-out alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
