<template>
  <div class="flex antialiased bg-gray-50">
    <NavBar />
    <SidebarComponent />
    <div class="flex pt-16 overflow-hidden bg-gray-50">
      <div
        id="main-content"
        class="relative w-full h-full overflow-y-auto bg-gray-50 min-h-full">
        <div
          class="grid grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pl-6 pt-4">
          <!-- Card Item -->
          <div
            class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
              class="rounded-t-lg h-[200px] w-full object-cover"
              src="https://source.unsplash.com/random"
              alt="" />
            <div class="p-5">
              <h5
                class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                June 2023
              </h5>
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Download a PDF detailing your exposure sources, details,
                remediation methods, and other tips on keeping your data yours.
              </p>
              <router-link to="reports/123">
                <a
                  class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Download PDF
                  <ArrowDownCircleIcon class="w-4 h-4 ml-2 -mr-1" />
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NavBar from '@/components/NavBar.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ArrowDownCircleIcon } from '@heroicons/vue/24/solid';
import { onMounted } from 'vue';
import { initFlowbite } from 'flowbite';

export default {
  name: 'ReportsView',
  components: {
    NavBar,
    SidebarComponent,
    FooterComponent,
    ArrowDownCircleIcon,
  },
  setup() {
    onMounted(() => {
      initFlowbite();
    });
  },
};
</script>
