<template>
  <div class="flex flex antialiased bg-gray-50">
    <NavBar />
    <SidebarComponent />
    <TransitionRoot
      appear
      :show="
        userStore.user.subscription?.status == 'Active' &&
        userStore.user.subscription?.planName == 'Family'
      ">
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full">
        <section
          tabindex="-1"
          class="hidden md:block pt-14 sticky inset-y-0 z-10 flex-shrink-0 w-64 bg-white border-r lg:static focus:outline-none h-full">
          <div class="flex flex-col h-full">
            <!-- Panel header -->
            <div class="flex-shrink-0 shadow-sm" v-if="false">
              <div class="bg-slate-50 px-4 py-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <span class="text-base font-semibold leading-6">
                    Family Profiles
                  </span>
                </div>
                <div class="mt-1">
                  <p class="text-sm text-gray-800">
                    Manage your family plan members.
                  </p>
                </div>
              </div>
            </div>

            <!-- Panel content -->
            <div
              class="flex-1 justify-center pt-4 overflow-y-hidden hover:overflow-y-auto">
              <div class="pl-2 pr-2">
                <!-- Content -->
                <template v-if="familyMembers">
                  <ul>
                    <li
                      v-for="familyMember of familyMembers"
                      :key="familyMember.id">
                      <FamilyMemberButton
                        class="mr-2 mb-2"
                        :disabled="familyMemberButtonsDisabled"
                        :active="activeUserIdTab == familyMember.id"
                        @click="setTab(familyMember.id)"
                        :text="`${familyMember.firstName} ${familyMember.lastName}`" />
                    </li>
                  </ul>
                  <button
                    v-if="
                      userStore.user.subscription?.planName == 'Family' &&
                      userStore.user.subscription?.active == true &&
                      userStore.user.subscription?.maxFamilyMembers >
                        userStore.user.familyMembers?.length
                    "
                    @click="openAddPersonModal"
                    type="button"
                    class="w-full p-4 text-gray-400 bg-white hover:bg-gray-100 border-2 border-dashed border-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2 transition-opacity disabled:opacity-25 opacity-100"
                    :disabled="familyMemberButtonsDisabled">
                    <PlusCircleIcon
                      class="w-6 rounded-full mr-2 -ml-1 text-gray-400" />
                    Add a family member
                  </button>
                </template>
                <template v-else>
                  <ul>
                    <li>
                      <FamilyMemberButton :skeleton="true" class="mr-2 mb-2" />
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </section>
      </TransitionChild>
    </TransitionRoot>
    <div class="flex-1 pt-16 overflow-hidden bg-gray-50">
      <div
        id="main-content"
        class="relative w-full h-full overflow-y-auto bg-gray-50 min-h-full p-2">
        <div class="grid grid-cols-1 px-4 pt-2 xl:grid-cols-2 xl:gap-4">
          <!-- Right Content -->
          <div class="col-span-2">
            <FamilyMemberListbox
              ref="familyMemberListboxRef"
              class="mb-4 w-full sm:w-72 block sm:hidden"
              :show-add-family-member="
                userStore.user.subscription?.maxFamilyMembers >
                userStore.user.familyMembers?.length
              "
              @add-family-member="openAddPersonModal"
              @selected-person="setTab" />
            <GeneralSettings
              ref="settingsComponent"
              @started-updating-family-member="disableFamilyMemberButtons"
              @updated-family-member="updateFamilyMemberButton"
              @deleted-family-member="deleteFamilyMemberButton" />
            <!-- Only show the change password component if the logged in user is selected -->
            <ChangePassword v-if="!activeUserIdTab" />
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  </div>
  <AddPersonModal
    :showing="addPersonModal"
    @close="closeAddPersonModal"
    @created-family-member="addFamilyMemberButton" />
</template>

<script lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import NavBar from '@/components/NavBar.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import GeneralSettings from '@/components/GeneralSettings.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import AddPersonModal from '@/components/modals/AddPersonModal.vue';
import FamilyMemberButton from '@/components/FamilyMemberButton.vue';
import { initFlowbite } from 'flowbite';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import FamilyMemberListbox from '@/components/FamilyMemberListbox.vue';

export default {
  name: 'SettingsView',
  components: {
    NavBar,
    SidebarComponent,
    FooterComponent,
    GeneralSettings,
    ChangePassword,
    AddPersonModal,
    PlusCircleIcon,
    FamilyMemberButton,
    TransitionRoot,
    TransitionChild,
    FamilyMemberListbox,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  setup() {
    const userStore = useUserStore();
    const addPersonModal = ref(false);
    const familyMembers = ref();
    const activeUserIdTab = ref();
    const settingsComponent = ref();
    const familyMemberListboxRef = ref();
    const familyMemberButtonsDisabled = ref(false);

    onBeforeMount(async () => {
      familyMembers.value = await userStore.getFamilyMembers();
      familyMembers.value.unshift({
        id: null,
        firstName: userStore.user.firstName,
        lastName: userStore.user.lastName,
      });
      activeUserIdTab.value = familyMembers.value[0].id;
    });

    onMounted(async () => {
      initFlowbite();
      await userStore.getDetails();
    });

    const addFamilyMemberButton = async ({
      familyMemberId,
      firstName,
      lastName,
    }: {
      familyMemberId: string;
      firstName: string;
      lastName: string;
    }) => {
      familyMembers.value.push({
        id: familyMemberId,
        firstName,
        lastName,
      });
      await userStore.getDetails();
    };

    const updateFamilyMemberButton = async ({
      familyMemberId,
      firstName,
      lastName,
    }: {
      familyMemberId: string;
      firstName: string;
      lastName: string;
    }) => {
      familyMembers.value = familyMembers.value.map((item: any) =>
        item.id === familyMemberId ? { ...item, firstName, lastName } : item,
      );
      familyMemberButtonsDisabled.value = false;
      if (familyMemberListboxRef.value) {
        await familyMemberListboxRef.value.refreshFamilyMembers();
      }
    };

    const disableFamilyMemberButtons = async () => {
      familyMemberButtonsDisabled.value = true;
    };

    const deleteFamilyMemberButton = async (familyMemberId: string) => {
      familyMembers.value = familyMembers.value.filter(
        (item: any) => item.id !== familyMemberId,
      );
      if (familyMemberListboxRef.value) {
        await familyMemberListboxRef?.value.refreshFamilyMembers();
      }
      setTab();
    };

    const openAddPersonModal = () => {
      addPersonModal.value = true;
    };

    const closeAddPersonModal = () => {
      addPersonModal.value = false;
    };

    const setTab = (userId?: string) => {
      activeUserIdTab.value = userId;
      if (settingsComponent.value) {
        settingsComponent.value.refreshDefaultValues(userId || null);
      }
    };

    return {
      familyMembers,
      addPersonModal,
      activeUserIdTab,
      settingsComponent,
      familyMemberListboxRef,
      familyMemberButtonsDisabled,
      setTab,
      openAddPersonModal,
      closeAddPersonModal,
      addFamilyMemberButton,
      updateFamilyMemberButton,
      disableFamilyMemberButtons,
      deleteFamilyMemberButton,
    };
  },
};
</script>
