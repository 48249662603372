import { defineStore } from 'pinia';
import {
  SettingsForm,
  ChangePasswordForm,
  IgnoreExposureForm,
  ChangePlanForm,
  AddFamilyMemberForm,
  ForgotPasswordForm,
  RequestPasswordRestForm,
} from '@/models/user.model';
import apiClient from '@/utils/http-common';
import { PaymentMethodCard } from '@/types/interfaces';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: JSON.parse(localStorage.getItem('user') || 'false'),
    previewPlan: {} as ChangePlanForm,
    paymentMethods: [] as PaymentMethodCard[],
    subscription: {
      planName: <string | null>null,
      interval: <string | null>null,
      cost: <number | null>null,
      status: <string | null>null,
      active: <boolean>false,
      maxFamilyMembers: <number | null>null,
      nextBillingDate: <string>'',
      cancelAtPeriodEnd: <boolean>false,
    },
    fetchingDetails: false,
    subscriptionPolling: <number | any>null,
    pollingForNewPlan: false,
  }),
  actions: {
    async getDetails(familyMemberId?: string) {
      this.fetchingDetails = true;
      const response = await apiClient.get(
        `/account/details/${familyMemberId || ''}`,
      );
      if (response.data && response.data.firstName && !familyMemberId) {
        this.subscription = response.data.subscription;
        this.user = response.data;
        localStorage.setItem('user', JSON.stringify(this.user));
      }
      this.fetchingDetails = false;
      return response.data;
    },
    async setUserDetails(form: SettingsForm, familyMemberId: string) {
      if (!familyMemberId) {
        familyMemberId = '';
      }
      const response = await apiClient.post(
        `/account/update/${familyMemberId || ''}`,
        form,
      );
      if (!familyMemberId) {
        localStorage.setItem('user', JSON.stringify(this.user));
        this.user = response.data;
      }
    },
    async changePassword(data: ChangePasswordForm) {
      const response = await apiClient.post('/account/change-password', data);
      return response.data;
    },
    async requestPasswordReset(data: RequestPasswordRestForm) {
      const response = await apiClient.post(
        '/account/request-password-reset',
        data,
      );
      return response.data;
    },
    async forgotPassword(data: ForgotPasswordForm) {
      const response = await apiClient.post('/account/forgot-password', data);
      return response.data;
    },
    async getExposures(familyMemberId?: string) {
      const response = await apiClient.get(
        `/account/exposures/${familyMemberId || ''}`,
      );
      return response.data;
    },
    async getHistoricalExposures(familyMemberId?: string) {
      const response = await apiClient.get(
        `/account/historical-exposures/${familyMemberId || ''}`,
      );
      return response.data;
    },
    async ignoreExposure(data: IgnoreExposureForm) {
      const response = await apiClient.post('/account/exposures/ignore', data);
      return response.data;
    },
    async getPredictedRelatives() {
      const response = await apiClient.get('/account/predicted-relatives');
      return response.data;
    },
    async getFamilyMembers() {
      const response = await apiClient.get('/account/family-members');
      return response.data;
    },
    async getAvailablePlans() {
      const response = await apiClient.get('/account/available-plans');
      return response.data;
    },
    async addFamilyMember(data: AddFamilyMemberForm) {
      const response = await apiClient.post('/account/family-member', data);
      return response.data;
    },
    async deleteFamilyMember(familyMemberId: string) {
      const response = await apiClient.delete(
        `/account/family-member/${familyMemberId || ''}`,
      );
      return response.data;
    },
    async pollSubscription() {
      this.pollingForNewPlan = true;
      this.subscriptionPolling = setInterval(async () => {
        if (await this.getDetails()) {
          this.pollingForNewPlan = false;
          if (this.subscriptionPolling) {
            clearInterval(this.subscriptionPolling);
          }
        }
      }, 2000);
    },
  },
});
