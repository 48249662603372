<template>
  <input
    type="text"
    v-maska
    data-maska="##/##/####"
    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    :class="{
      'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
        failed,
    }"
    placeholder="mm/dd/yyyy" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { vMaska } from 'maska';

export default defineComponent({
  name: 'DatePicker',
  directives: { maska: vMaska },
  props: {
    failed: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
