import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    "data-maska": "##/##/####",
    class: _normalizeClass(["shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500", {
      'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
        _ctx.failed,
    }]),
    placeholder: "mm/dd/yyyy"
  }, null, 2)), [
    [_directive_maska]
  ])
}