<template>
  <!-- Render inside our `<div id="modals"></div>` in index.html -->
  <Teleport to="#modals">
    <!-- Show / hide the modal -->
    <transition name="fade" appear>
      <div v-if="showing">
        <!-- The backdrop -->
        <div class="fixed inset-0 bg-gray-900 opacity-40" @click="close"></div>

        <div
          name="baseModel"
          tabindex="-1"
          class="fixed overflow-hidden inset-0 flex items-center justify-center p-2">
          <div
            class="relative w-full max-w-2xl max-h-full overflow-y-auto rounded-lg">
            <!-- Modal content -->
            <div class="relative bg-white shadow">
              <!-- Modal header -->
              <div
                class="flex items-center justify-between pl-5 pr-5 py-3 border-b rounded-t">
                <h3 class="p-1.5 inline-flex">
                  <slot name="header"></slot>
                </h3>
                <button
                  type="button"
                  @click="close"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <!-- Modal body p-6 space-y-6 -->
              <div class="px-6 pt-6">
                <slot name="featuredImage"></slot>
                <!-- overflow-y-scroll -->
                <div
                  class="overflow-visible md:inset-0 h-[calc(100%-1rem)] max-h-full h-[160px]">
                  <slot name="body"></slot>
                </div>
              </div>
              <!-- Modal footer -->
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import '@websitebeaver/vue-magnifier/styles.css';

export default defineComponent({
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        this.close();
      }
    });
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
