<template>
  <TransitionRoot as="template" :show="isOpen">
    <DialogUI as="div" class="relative z-30" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <ShieldCheckIcon
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900">
                    Upgrade to a Family Plan
                  </DialogTitle>
                  <div class="mt-4">
                    <ul
                      class="text-sm max-w-md space-y-1 text-gray-500 list-inside">
                      <li class="flex items-center">
                        <svg
                          class="w-3.5 h-3.5 mr-2 text-green-500 blueflex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Coverage for you and 4 family members
                      </li>
                      <li class="flex items-center">
                        <svg
                          class="w-3.5 h-3.5 mr-2 text-green-500 blueflex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Removal from 50+ data brokers
                      </li>
                      <li class="flex items-center">
                        <svg
                          class="w-3.5 h-3.5 mr-2 text-green-500 blueflex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Automatic scans every week
                      </li>
                      <li class="flex items-center">
                        <svg
                          class="w-3.5 h-3.5 mr-2 text-green-500 blueflex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Human privacy agent
                      </li>
                      <li class="flex items-center" v-if="false">
                        <svg
                          class="w-3.5 h-3.5 mr-2 text-green-500 blueflex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Monthly reports
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <router-link to="premium" tabindex="-1">
                  <SubmitButton
                    variant="success"
                    tabindex="-1"
                    class="w-full justify-center rounded-md"
                    ref="cancelButtonRef">
                    Show me more
                  </SubmitButton>
                </router-link>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </DialogUI>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import {
  Dialog as DialogUI,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { ShieldCheckIcon } from '@heroicons/vue/24/outline';
import SubmitButton from './forms/SubmitButton.vue';

export default defineComponent({
  name: 'UpsellModal',
  components: {
    DialogUI,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    SubmitButton,
    ShieldCheckIcon,
  },
  emits: ['confirm'],
  setup(_, { emit }) {
    const isOpen = ref(false);

    const openModal = () => {
      isOpen.value = true;
    };

    const closeModal = () => {
      isOpen.value = false;
    };

    const confirmModal = () => {
      closeModal();
      emit('confirm');
    };

    return { isOpen, openModal, closeModal, confirmModal };
  },
});
</script>
