<template>
  <div
    class="bg-white border border-gray-200 rounded-lg shadow-sm p-2 pb-6 pt-6">
    <div class="flex flex-col items-center">
      <img
        class="w-8 rounded-md"
        :src="
          'https://s3.us-west-1.amazonaws.com/privacyzen.media/favicons/' +
          exposure.source.url +
          '.png'
        "
        alt="Bonnie image" />
      <h5
        class="mb-3 mt-3 text-xl font-medium text-gray-900 truncate w-10/12 text-center">
        {{ exposure.source.name }}
      </h5>
      <ul class="text-center space-y-2">
        <li
          v-for="user in exposure.owners"
          :key="user"
          class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
          <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
          {{ user.firstName }} {{ user.lastName }}
        </li>
      </ul>
      <div class="hidden md:block">
        <div class="flex flex-nowrap justify-around mt-6 px-2">
          <ul
            class="space-y-2 max-w-1/2"
            v-for="(fieldGroup, i) in fieldGroups"
            :key="i">
            <li
              v-for="field in fieldGroup"
              :KEY="field.key"
              class="flex"
              :class="{
                'opacity-50': !exposure.source.exposedFields.includes(
                  field.key,
                ),
              }">
              <div class="flex-shrink-0 mr-2">
                <component
                  :is="field.icon"
                  class="w-4 text-gray-900 opacity-100" />
              </div>
              <div>
                <h3 class="text-sm font-semibold">{{ field.key }}</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ClockIcon,
  UserCircleIcon,
  HomeIcon,
  PhoneIcon,
  UserGroupIcon,
  HeartIcon,
  CameraIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
  PhotoIcon,
  EnvelopeIcon,
  UsersIcon,
  DocumentTextIcon,
} from '@heroicons/vue/24/outline';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExposureCard',
  computed: {
    fieldGroups(): any {
      const middleIndex = Math.ceil(this.fields.length / 2);
      const firstHalf = this.fields.slice(0, middleIndex);
      const secondHalf = this.fields.slice(middleIndex);
      return [firstHalf, secondHalf];
    },
  },
  props: {
    exposure: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      fields: [
        {
          key: 'Name',
          icon: UserCircleIcon,
        },
        {
          key: 'Age',
          icon: ClockIcon,
        },
        {
          key: 'Addresses',
          icon: HomeIcon,
        },
        {
          key: 'Phone',
          icon: PhoneIcon,
        },
        {
          key: 'Relatives',
          icon: UserGroupIcon,
        },
        {
          key: 'Spouse',
          icon: HeartIcon,
        },
        {
          key: 'Social Media',
          icon: CameraIcon,
        },
        {
          key: 'Photos',
          icon: PhotoIcon,
        },
        {
          key: 'Email',
          icon: EnvelopeIcon,
        },
        {
          key: 'Occupation',
          icon: BriefcaseIcon,
        },
        {
          key: 'Marital Status',
          icon: UsersIcon,
        },
        {
          key: 'Property Value',
          icon: CurrencyDollarIcon,
        },
        {
          key: 'Court Records',
          icon: DocumentTextIcon,
        },
      ],
    };
  },
  setup() {
    const generateRandomNumber = () => {
      return Math.floor(Math.random() * 100) + 1;
    };

    return { generateRandomNumber };
  },
});
</script>
