<template>
  <div id="g-recaptcha"></div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  emits: ['verify'],
  setup(_, { emit }) {
    const execute = () => {
      window.grecaptcha.execute();
    };

    const reset = () => {
      window.grecaptcha.reset();
    };

    const render = () => {
      if (window.grecaptcha) {
        window.grecaptcha.render('g-recaptcha', {
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          size: 'invisible',
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            emit('verify', response);
            // reset the recaptcha widget so you can execute it again
            reset();
          },
        });
      }
    };

    onMounted(() => {
      render();
    });

    return {
      execute,
      reset,
      render,
    };
  },
};
</script>
