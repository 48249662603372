<template>
  <li class="flex items-center pl-4 rounded animate-pulse">
    <input
      disabled
      id="bordered-radio-1"
      type="radio"
      value=""
      name="bordered-radio"
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
    <label
      for="bordered-radio-1"
      class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 text-gray-300">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
          </svg>
          <div
            class="ml-2 h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32"></div>
          <div
            class="ml-4 w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div
          class="mr-4 w-8 inline-flex h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
    </label>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentMethodsSkeleton',
  components: {},
});
</script>
