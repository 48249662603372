import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex antialiased bg-gray-50" }
const _hoisted_2 = { class: "flex overflow-hidden bg-gray-50 w-full mt-10" }
const _hoisted_3 = {
  id: "main-content",
  class: "relative w-full h-full overflow-y-auto bg-gray-50 min-h-full pt-6"
}
const _hoisted_4 = { class: "px-4 pt-6 max-w-[600px] mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_PlanInfo = _resolveComponent("PlanInfo")!
  const _component_SubscribeForm = _resolveComponent("SubscribeForm")!
  const _component_PaymentMethods = _resolveComponent("PaymentMethods")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar),
    _createVNode(_component_SidebarComponent),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_PlanInfo),
          (!$setup.userStore.subscription.active)
            ? (_openBlock(), _createBlock(_component_SubscribeForm, { key: 0 }))
            : _createCommentVNode("", true),
          ($setup.userStore.subscription.active)
            ? (_openBlock(), _createBlock(_component_PaymentMethods, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}