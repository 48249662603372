/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormActions } from 'vee-validate';
import * as yup from 'yup';

const getSubmit =
  <Schema extends yup.ObjectSchema<Record<string, any>>>(
    _: Schema,
    callback: (
      values: yup.InferType<Schema>,
      actions: FormActions<yup.InferType<Schema>, unknown>,
    ) => void,
  ) =>
  (
    values: Record<string, any>,
    actions: FormActions<yup.InferType<Schema>, unknown>,
  ) =>
    callback(values, actions);

export { getSubmit };
