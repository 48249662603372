<template>
  <template v-if="exposures.length > 0">
    <ul class="divide-y divide-gray-200 dark:divide-gray-700">
      <li
        v-for="(exposure, index) in exposures"
        :key="exposure.source"
        :class="{
          'opacity-40 grayscale': exposure.ignore,
          'py-4': index != 0,
          'pb-4': index == 0,
        }">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0">
            <img
              class="w-6 h-6 object-cover dark:text-white rounded-md"
              :src="
                'https://s3.us-west-1.amazonaws.com/privacyzen.media/favicons/' +
                exposure.source.url +
                '.png'
              " />
          </div>
          <div class="flex-1 min-w-0">
            <p class="text-base font-semibold text-gray-900">
              {{ exposure.source.name }}
              <span
                class="text-xs font-medium inline-flex items-center px-1 py-0.5 rounded border mr-2"
                v-if="!exposure.ignore"
                :class="{
                  'bg-blue-100 text-blue-800 border-blue-400':
                    exposure.status === 'In Progress',
                  'bg-yellow-100 text-yellow-800 border-yellow-400':
                    exposure.status === 'Escalated',
                  'bg-green-100 text-green-800 border-green-400':
                    exposure.status === 'Removed',
                }">
                <ClockIcon
                  class="w-3 h-3 mr-1"
                  v-if="exposure.status == 'In Progress'"></ClockIcon>
                <TrashIcon
                  class="w-3 h-3 mr-1"
                  v-if="exposure.status == 'Removed'"></TrashIcon>
                <ChatBubbleLeftIcon
                  class="w-3 h-3 mr-1"
                  v-if="exposure.status == 'Escalated'"></ChatBubbleLeftIcon>
                {{ exposure.status }}
              </span>
              <span
                class="text-xs font-medium inline-flex items-center px-1 py-0.5 rounded border mr-2"
                v-if="exposure.google">
                <img
                  class="w-3 h-3 mr-1"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1176px-Google_%22G%22_Logo.svg.png?20230305195327" />
                GOOGLE RESULT
              </span>
            </p>
            <p
              class="text-sm font-normal text-gray-500 truncate dark:text-gray-400">
              Found {{ formatDate(exposure.createdAt) }}
            </p>
          </div>
          <div class="inline-flex items-center">
            <button
              @click="viewRecord(exposure)"
              class="inline-flex items-center px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-300">
              View Record
            </button>
          </div>
        </div>
      </li>
    </ul>
  </template>
  <template v-else>
    <div class="text-center pb-6">
      <FaceSmileIcon class="mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 font-semibold text-gray-900">No exposures</h3>
      <p class="mt-2 text-gray-500">
        Sit back and relax. We'll let you know if your data is exposed.
      </p>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import {
  ClockIcon,
  TrashIcon,
  ChatBubbleLeftIcon,
} from '@heroicons/vue/24/solid';
import { FaceSmileIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'ExposureList',
  components: {
    ClockIcon,
    TrashIcon,
    ChatBubbleLeftIcon,
    FaceSmileIcon,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  emits: ['showDetails', 'loaded'],
  props: {
    ignoredExposureIds: {
      type: Array,
      required: true,
    },
    selectedPersonId: {
      type: String,
      default: null,
    },
  },
  async setup(props, { emit }) {
    const selectedPersonIdRef = toRef(props, 'selectedPersonId');
    const userStore = await useUserStore();
    const exposures = ref(
      (await userStore.getExposures(selectedPersonIdRef.value)).exposures,
    );

    watch(selectedPersonIdRef, async (newId) => {
      exposures.value = (await userStore.getExposures(newId)).exposures;
      emit('loaded', exposures.value);
    });

    const viewRecord = (exposure: any) => {
      emit('showDetails', exposure);
    };

    emit('loaded', exposures.value);

    return {
      exposures,
      viewRecord,
    };
  },
  methods: {
    formatDate(dateStr: string) {
      const date = new Date(dateStr);
      const options: any = {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      const formattedDate = date.toLocaleString('en-US', options);
      const day = date.getDate();
      const suffix =
        day % 10 === 1 && day !== 11
          ? 'st'
          : day % 10 === 2 && day !== 12
          ? 'nd'
          : day % 10 === 3 && day !== 13
          ? 'rd'
          : 'th';

      return formattedDate.replace(/\b(\d+)\b/, '$1' + suffix);
    },
  },
  watch: {
    ignoredExposureIds: {
      handler(ignoredExposureIds) {
        for (const exposure of this.exposures) {
          const obj = ignoredExposureIds.find(
            (obj: any) => obj.id === exposure.id,
          );
          if (obj) {
            exposure.ignore = obj.ignore;
          }
        }
      },
      deep: true,
    },
  },
});
</script>
