<template>
  <input
    ref="autocompleteInput"
    type="text"
    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
    :class="{
      'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
        failed,
    }" />
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PlaceAutocomplete',
  emits: ['localityChanged'],
  props: {
    autocompleteId: {
      type: String,
      required: true,
    },
    failed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const autocompleteInput = ref(null);

    const initAutocomplete = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBFkK0SxeuTlkLaoIKgH69D2cCD4Ek-f3k',
        version: 'weekly',
        region: 'us',
        libraries: ['places'],
      });

      await loader.load().then((google) => {
        const options = {
          types: ['(cities)'],
          componentRestrictions: { country: 'us' },
        };
        const autocomplete = new google.maps.places.Autocomplete(
          autocompleteInput.value,
          options,
        );

        // Handle place selection
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (place && place.address_components) {
            let city = '';
            let state = '';

            // Find the city component
            for (let i = 0; i < place.address_components.length; i++) {
              const component = place.address_components[i];

              if (component.types.includes('locality')) {
                city = component.long_name;
              }

              if (component.types.includes('administrative_area_level_1')) {
                state = component.short_name;
              }
            }

            emit('localityChanged', { city, state });
          }
        });
      });
    };

    return { autocompleteInput, initAutocomplete };
  },
  mounted() {
    this.initAutocomplete();
  },
});
</script>
