<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <h3 class="mb-4 text-xl font-semibold dark:text-white">Change Password</h3>
    <VeeForm
      :validation-schema="validationSchema"
      @submit="onSubmit"
      id="changePassword"
      as="form"
      v-slot="{ errorBag }">
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
          <label
            for="current_password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Current password
          </label>
          <Field name="current_password" type="text" v-slot="{ field, errors }">
            <TextInput
              v-bind="field"
              type="password"
              name="current_password"
              id="current_password"
              placeholder="••••••••"
              :failed="!!errors.length" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="current_password" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="new_password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            New password
          </label>
          <Field name="new_password" type="text" v-slot="{ field, errors }">
            <TextInput
              v-bind="field"
              type="password"
              name="new_password"
              id="new_password"
              placeholder="••••••••"
              :failed="!!errors.length" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="new_password" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="confirm_new_password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Confirm password
          </label>
          <Field
            name="confirm_new_password"
            type="text"
            v-slot="{ field, errors }">
            <TextInput
              v-bind="field"
              type="password"
              name="confirm_new_password"
              id="confirm_new_password"
              placeholder="••••••••"
              :failed="!!errors.length" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="confirm_new_password" />
        </div>
        <div class="col-span-6 sm:col-full">
          <SubmitButton
            type="submit"
            :loading="changePasswordStatus == 'loading'"
            :success="changePasswordStatus == 'success'"
            :failed="changePasswordStatus == 'failed'"
            :input-errors="Object.keys(errorBag).length > 0"
            class="w-44 h-10">
            Change Password
          </SubmitButton>
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script lang="ts">
import * as yup from 'yup';
import { defineComponent, ref } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import { ErrorMessage, Field, Form as VeeForm } from 'vee-validate';
import { getSubmit } from '@/utils/form-helpers';
import { ChangePasswordForm } from '@/models/user.model';
import TextInput from '@/components/forms/TextInput.vue';
import SubmitButton from '@/components/forms/SubmitButton.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    TextInput,
    VeeForm,
    Field,
    ErrorMessage,
    SubmitButton,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  setup() {
    const changePasswordStatus = ref('');

    const userStore = useUserStore();
    const validationSchema = yup.object({
      current_password: yup.string().required().label('Current password'),
      new_password: yup
        .string()
        .required('Please enter your new password')
        .min(6, 'Must be at least 6 characters')
        .label('New password'),
      confirm_new_password: yup
        .string()
        .required('Please confirm your new password')
        .oneOf([yup.ref('new_password')], 'The passwords must match')
        .label('Confirm Password'),
    });

    const onSubmit = getSubmit(validationSchema, (values, actions) => {
      changePasswordStatus.value = 'loading';
      const data: ChangePasswordForm = {
        current_password: values['current_password'],
        new_password: values['new_password'],
        confirm_new_password: values['confirm_new_password'],
      };
      userStore.changePassword(data).then(async (responseData) => {
        if (!responseData['success']) {
          await new Promise((r) => setTimeout(r, 1000));
          changePasswordStatus.value = 'failed';
          actions.setFieldError(
            'current_password',
            'Incorrect current password',
          );
          await new Promise((r) => setTimeout(r, 1000));
          changePasswordStatus.value = '';
        } else {
          await new Promise((r) => setTimeout(r, 1000));
          changePasswordStatus.value = 'success';
          await new Promise((r) => setTimeout(r, 1000));
          changePasswordStatus.value = '';
        }
      });
    });

    return {
      changePasswordStatus,
      validationSchema,
      onSubmit,
    };
  },
});
</script>
