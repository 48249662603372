<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6">
    <div
      class="flex justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700">
      <div class="flex items-center">
        <div
          class="w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center mr-3">
          <PresentationChartLineIcon class="w-6 h-6 text-gray-500" />
        </div>
        <div>
          <h3
            class="leading-none text-xl font-semibold text-gray-900 dark:text-white pb-1">
            Historical Exposures
          </h3>
        </div>
      </div>
    </div>
    <apexchart
      ref="ExposuresLineChartRef"
      type="area"
      height="250"
      :options="chartOptions"
      :series="[
        {
          name: 'Exposures',
          data: Object.values(removalSeries),
          color: '#008ffb',
        },
      ]"></apexchart>
  </div>
</template>

<script lang="ts">
import { useUserStore } from '@/store/user.store';
import { PresentationChartLineIcon } from '@heroicons/vue/24/outline';
import { defineComponent, ref, toRef, onMounted, watch } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
    PresentationChartLineIcon,
  },
  props: {
    selectedPersonId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedPersonIdRef = toRef(props, 'selectedPersonId');
    const removalSeries = ref([]); // Initialize it as an empty array
    const ExposuresLineChartRef = ref();

    const chartOptions = ref({
      chart: {
        type: 'area',
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        width: 7,
        curve: 'straight',
      },
      forecastDataPoints: {
        // count: 2,
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.55,
          opacityTo: 0,
          shade: '#008ffb',
          gradientToColors: ['#008ffb'],
        },
      },
      markers: {
        size: 4,
        colors: ['#008ffb'],
        strokeColors: '#fff',
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    });

    const getRemovalChartData = async () => {
      try {
        const userStore = await useUserStore();
        const historicalExposures = await userStore.getHistoricalExposures(
          selectedPersonIdRef.value,
        );
        // Assuming exposures.historicalExposures is an array of data you want to assign to removalSeries
        const seriesData = historicalExposures.map((dataPoint: any) => ({
          x: dataPoint.period, // x-axis value, convert the date to a timestamp
          y: dataPoint.numExposures, // y-axis value
        }));
        removalSeries.value = seriesData;
        if (ExposuresLineChartRef.value) {
          ExposuresLineChartRef.value.updateOptions({
            xaxis: {
              type: 'datetime',
              categories: Object.keys(removalSeries.value),
              labels: {
                style: {
                  fontFamily:
                    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              min: 0,
              tickAmount: 5,
              max: Math.max(...seriesData.map((dataPoint: any) => dataPoint.y)),
              labels: {
                style: {
                  fontFamily:
                    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                },
              },
            },
          });
        }
        emit('data-changed', historicalExposures);
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching exposure data:', error);
      }
    };

    watch(selectedPersonIdRef, async () => {
      await getRemovalChartData();
    });

    onMounted(() => {
      // Fetch exposure data when the component is mounted
      getRemovalChartData();
    });

    return { removalSeries, chartOptions, ExposuresLineChartRef };
  },
});
</script>
