<template>
  <TextInput
    type="text"
    :class="{
      'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
        failed,
    }"
    v-maska
    data-maska="(###) ###-####"
    placeholder="(505) 555-0193" />
</template>

<script lang="ts">
import { vMaska } from 'maska';
import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PhoneInput',
  directives: { maska: vMaska },
  components: { TextInput },
  props: {
    failed: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
