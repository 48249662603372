<template>
  <div
    v-if="selectedPerson && (showAddFamilyMember || familyMembers.length > 1)">
    <Listbox
      v-model="selectedPerson"
      @update:model-value="(value) => $emit('selected-person', value.id)">
      <div class="relative">
        <ListboxButton
          class="relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-200 rounded-lg shadow-sm focus:outline-none hover:shadow focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
          <span class="block truncate">
            {{ selectedPerson.firstName }} {{ selectedPerson.lastName }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-10 py-1 text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="person in familyMembers"
              :key="person.id"
              :value="person"
              as="template">
              <li
                :class="[
                  active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                  'relative cursor-pointer select-none py-2 pl-10 pr-4',
                ]">
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]">
                  {{ person.firstName }} {{ person.lastName }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
            <li v-if="showAddFamilyMember">
              <a
                @click="$emit('addFamilyMember')"
                class="group flex items-center px-4 py-2 cursor-pointer">
                <PlusCircleIcon
                  class="mr-3 h-5 w-5 text-blue-700 group-hover:text-blue-800"
                  aria-hidden="true" />
                Add family member
              </a>
            </li>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { useUserStore } from '@/store/user.store';
import { PlusCircleIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  name: 'FamilyMemberListbox',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    ChevronUpDownIcon,
    PlusCircleIcon,
  },
  emits: ['selected-person', 'addFamilyMember'],
  props: {
    showAddFamilyMember: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUserStore();
    const familyMembers = ref();
    const selectedPerson = ref();

    const refreshFamilyMembers = async () => {
      familyMembers.value = [
        {
          id: null,
          firstName: userStore.user.firstName,
          lastName: userStore.user.lastName,
        },
        ...(await userStore.getFamilyMembers()),
      ];
      const existingSelection = familyMembers.value.findIndex(
        (member: any) => member.id === selectedPerson.value?.id,
      );
      selectedPerson.value = familyMembers.value[existingSelection];
    };

    onBeforeMount(async () => {
      await refreshFamilyMembers();
      selectedPerson.value = familyMembers.value[0];
    });

    return { familyMembers, selectedPerson, refreshFamilyMembers };
  },
});
</script>
