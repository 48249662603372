<template>
  <li class="py-4 animate-pulse list-none">
    <div class="flex items-center space-x-4">
      <div class="flex-shrink-0">
        <div class="w-6 h-6 bg-gray-200 rounded-md dark:bg-gray-700"></div>
      </div>
      <div class="flex-1 min-w-0">
        <div class="flex">
          <div
            class="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mr-3"></div>
          <div
            class="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div
          class="w-28 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mt-2"></div>
      </div>
      <div class="inline-flex items-center">
        <button
          disabled
          class="bg-gray-200 inline-flex items-center px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 rounded-lg text-opacity-0">
          View Record
        </button>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExposureSkeleton',
});
</script>
