<template>
  <label
    :for="forInput"
    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg peer-checked:outline peer-checked:outline-2 peer-checked:outline-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
    :class="{
      'cursor-pointer': active,
    }">
    <div>
      <div class="flex items-baseline justify-between">
        <div class="text-lg font-semibold">{{ name }}</div>
        <slot name="badge"></slot>
      </div>
      <div class="align-baseline mt-.5 mb-.5">
        <span class="text-lg font-bold text-gray-800">{{ price }}</span>
        <span class="text-gray-400 text-sm">/{{ interval }}</span>
      </div>
      <slot name="description"></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlanCard',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    forInput: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
  },
});
</script>
