<template>
  <div class="flex antialiased bg-gray-50">
    <NavBar />
    <SidebarComponent />
    <div class="flex overflow-hidden bg-gray-50 w-full mt-10">
      <div
        id="main-content"
        class="relative w-full h-full overflow-y-auto bg-gray-50 min-h-full pt-6">
        <div class="px-4 pt-6 max-w-[600px] mx-auto">
          <PlanInfo />
          <SubscribeForm v-if="!userStore.subscription.active" />
          <PaymentMethods v-if="userStore.subscription.active" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted } from 'vue';
import { useUserStore } from '@/store/user.store';
import SidebarComponent from '@/components/SidebarComponent.vue';
import NavBar from '@/components/NavBar.vue';
import PlanInfo from '@/components/billing/PlanInfo.vue';
import PaymentMethods from '@/components/billing/PaymentMethods.vue';
import SubscribeForm from '@/components/billing/SubscribeForm.vue';
import { initFlowbite } from 'flowbite';

export default {
  name: 'PlansView',
  components: {
    SidebarComponent,
    NavBar,
    PaymentMethods,
    PlanInfo,
    SubscribeForm,
  },
  setup() {
    const userStore = useUserStore();

    onMounted(async () => {
      await userStore.getDetails();
      initFlowbite();
    });

    return {
      userStore,
    };
  },
};
</script>
