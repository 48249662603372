import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "flex items-baseline justify-between" }
const _hoisted_3 = { class: "text-lg font-semibold" }
const _hoisted_4 = { class: "align-baseline mt-.5 mb-.5" }
const _hoisted_5 = { class: "text-lg font-bold text-gray-800" }
const _hoisted_6 = { class: "text-gray-400 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.forInput,
    class: _normalizeClass(["inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg peer-checked:outline peer-checked:outline-2 peer-checked:outline-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100", {
      'cursor-pointer': _ctx.active,
    }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
        _renderSlot(_ctx.$slots, "badge")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.price), 1),
        _createElementVNode("span", _hoisted_6, "/" + _toDisplayString(_ctx.interval), 1)
      ]),
      _renderSlot(_ctx.$slots, "description")
    ])
  ], 10, _hoisted_1))
}