<template>
  <div
    class="relative overflow-hidden bg-white border border-gray-200 rounded-lg shadow-sm">
    <div class="overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th v-for="column in columns" :key="column.alias" class="px-4 py-3">
              {{ column.alias }}
            </th>
            <th scope="col" class="px-4 py-3">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b hover:bg-gray-100"
            v-for="(item, index) in paginatedData"
            :key="index">
            <td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              :class="{
                'opacity-25': item?.ignore,
              }">
              <slot :name="column.key" :data="item">
                {{ getCellValue(item, column.reference) }}
              </slot>
            </td>
            <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
              <slot name="action" :data="item"></slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav
      class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
      aria-label="Table navigation">
      <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing
        <span class="font-semibold text-gray-900 dark:text-white">
          {{ showingFrom }}-{{ showingTo }}
        </span>
        of
        <span class="font-semibold text-gray-900 dark:text-white">
          {{ data.length }} {{ units }}
        </span>
      </span>
      <TableViewerPaginator
        :current-page="currentPage"
        :total-pages="totalPages"
        @page-change="changePage" />
    </nav>
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRef, watch } from 'vue';
import TableViewerPaginator from './TableViewerPaginator.vue';
import { initFlowbite } from 'flowbite';

export default defineComponent({
  name: 'TableViewer',
  props: {
    units: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      required: true,
    },
    disabledRows: {
      type: Array,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10, // Number of items per page
    },
  },
  updated() {
    initFlowbite();
  },
  mounted() {
    initFlowbite();
  },
  setup(props) {
    const dataRef = toRef(props, 'data');
    const disabledRowsRef = toRef(props, 'disabledRows');

    const currentPage = ref(1); // Create a reactive property
    const totalPages = computed(() => {
      return Math.ceil(dataRef.value.length / props.perPage);
    });

    const paginatedData = computed(() => {
      const startIndex = (currentPage.value - 1) * props.perPage;
      const endIndex = startIndex + props.perPage;
      return dataRef.value.slice(startIndex, endIndex);
    });

    const showingFrom = computed(() => {
      return (currentPage.value - 1) * props.perPage + 1;
    });

    const showingTo = computed(() => {
      const lastItemIndex = currentPage.value * props.perPage;
      return Math.min(lastItemIndex, dataRef.value.length);
    });

    const changePage = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages.value) {
        currentPage.value = newPage;
      }
    };

    watch(dataRef, () => {
      changePage(1);
    });

    watch(
      disabledRowsRef,
      (disabledRows) => {
        for (const row of dataRef.value) {
          const obj = disabledRows.find((obj) => obj.id === row?.id);
          if (obj) {
            row.ignore = obj.ignore;
          }
        }
      },
      { deep: true },
    );

    return {
      currentPage,
      totalPages,
      paginatedData,
      showingFrom,
      showingTo,
      changePage,
    };
  },
  components: { TableViewerPaginator },
});
</script>
