import type { NavigationGuard } from 'vue-router';
import { useAuthStore } from '@/store/auth.store';
import { useJwt } from '@vueuse/integrations/useJwt';
import { TimeUtils } from '@/utils/time.util';

export const authGuard: NavigationGuard = async (to, from, next) => {
  const authStore = useAuthStore();

  // Check if the user is authenticated
  const { access_token } = authStore;
  const { payload } = useJwt(access_token);
  const isTokenExpired = TimeUtils.isTokenExpired(payload.value?.exp as number);

  if (access_token && !isTokenExpired) {
    next();
  } else if (isTokenExpired) {
    try {
      await authStore.refreshTokens();
      next();
    } catch (error) {
      authStore.logout();
      next('/login');
    }
  } else {
    authStore.logout();
    next('/login');
  }
};
