<template>
  <div>
    <template v-if="exposures.length > 0">
      <TableViewer
        :data="exposures"
        :disabled-rows="ignoredExposureIds"
        :columns="tableColumns"
        :per-page="perPage"
        units="exposures">
        <template #name="exposure">
          <div class="flex items-center">
            <img
              :src="
                'https://s3.us-west-1.amazonaws.com/privacyzen.media/favicons/' +
                exposure.data.source.url +
                '.png'
              "
              :alt="exposure.data.source.name"
              class="w-auto h-6 rounded-md mr-3" />
            {{ exposure.data.source.name }}
            <img
              v-if="googleResults.includes(exposure.data.source.url)"
              :src="require('@/assets/GoogleIcon.png')"
              class="ml-1 w-5 bg-white shadow-sm border p-0.5 rounded-2xl"
              data-popover-target="google-popover" />
          </div>
        </template>
        <template #status="exposure">
          <span
            class="text-xs font-medium inline-flex items-center px-1 py-0.5 rounded border mr-2"
            v-if="!exposure.data.source.ignore"
            :class="{
              'bg-blue-100 text-blue-800 border-blue-400':
                exposure.data.status === 'In Progress',
              'bg-yellow-100 text-yellow-800 border-yellow-400':
                exposure.data.status === 'Escalated',
              'bg-green-100 text-green-800 border-green-400':
                exposure.data.status === 'Removed',
            }">
            <ClockIcon
              class="w-3 h-3 mr-1"
              v-if="exposure.data.status == 'In Progress'"></ClockIcon>
            <TrashIcon
              class="w-3 h-3 mr-1"
              v-if="exposure.data.status == 'Removed'"></TrashIcon>
            <ChatBubbleLeftIcon
              class="w-3 h-3 mr-1"
              v-if="exposure.data.status == 'Escalated'"></ChatBubbleLeftIcon>
            {{ exposure.data.status }}
          </span>
        </template>
        <template #directUrl="exposure">
          <a
            :href="exposure.data.url"
            target="_BLANK"
            class="font-medium text-primary-600 dark:text-primary-500 hover:underline inline-flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
              <path
                d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
            </svg>
            Website
          </a>
        </template>
        <template #lastSeen="exposure">
          {{ formatDate(exposure.data.updatedAt) }}
        </template>
        <template #exposureRisk="exposure">
          <div class="flex items-center">
            <svg
              class="w-4 h-4 text-red-600 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <p class="text-sm font-bold text-gray-900 dark:text-white">
              {{
                Math.ceil((exposure.data.source.exposedFields.length / 13) * 10)
              }}
            </p>
          </div>
        </template>
        <template #action="exposure">
          <button
            id="dropdown_button"
            type="button"
            :data-dropdown-toggle="'dropdown_' + exposure.data.source.name"
            data-dropdown-placement="left"
            class="inline-flex items-center p-1 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-800 hover:bg-gray-200 focus:outline-none">
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
          <div
            :id="'dropdown_' + exposure.data.source.name"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
            <ul
              class="py-1 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdown-button-0">
              <li>
                <a
                  @click="showDetails(exposure.data)"
                  class="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  More Info
                </a>
              </li>
            </ul>
          </div>
        </template>
      </TableViewer>
    </template>
    <template v-else-if="exposuresLoadedRef">
      <div
        class="bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 px-6 pt-6 pb-2">
        <div
          class="items-center xl:block sm:space-x-4 xl:space-x-0 2xl:space-x-4">
          <div class="text-center pb-6">
            <FaceSmileIcon class="mx-auto h-12 w-12 text-gray-400" />
            <h3 class="mt-2 font-semibold text-gray-900">No exposures</h3>
            <p class="mt-2 text-gray-500">
              Sit back and relax. We'll let you know if your data is exposed.
            </p>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="false"
      class="bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 px-6 pt-6 pb-2">
      <div
        class="items-center xl:block sm:space-x-4 xl:space-x-0 2xl:space-x-4">
        <div>
          <div
            class="flex items-center justify-between mb-4"
            v-if="numExposures > 0">
            <h5
              class="text-xl font-bold leading-none text-gray-900 dark:text-white">
              {{ numExposures }} Public Exposures
            </h5>
          </div>
          <Suspense>
            <ExposureList
              @show-details="showDetails"
              @loaded="exposuresLoaded"
              :ignored-exposure-ids="ignoredExposureIds"
              :selected-person-id="selectedPersonIdRef" />
            <template #fallback>
              <ExposureSkeleton />
            </template>
          </Suspense>
        </div>
      </div>
    </div>
    <div
      data-popover
      id="google-popover"
      role="tooltip"
      class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0">
      <div
        class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
        <h3 class="font-semibold text-gray-900 dark:text-white">
          High Visibility
        </h3>
      </div>
      <div class="px-3 py-2">
        <p>
          We found this record on the first page of Google's search results.
        </p>
      </div>
      <div data-popper-arrow></div>
    </div>
    <BaseModal :showing="detailsModal" @close="closeDetails">
      <template #featuredImage>
        <div class="h-[300px] overflow-y-auto mb-6 rounded-lg border">
          <div
            class="flex items-center justify-center h-full bg-gray-50"
            v-if="!featureImageLoaded">
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <VueMagnifier
            :mg-show-overflow="false"
            @load="showFeaturedImage()"
            :style="{ display: featureImageLoaded ? 'block' : 'none' }"
            class="object-cover w-full"
            :src="exposureData.screenshot" />
        </div>
      </template>
      <template #header>
        <div class="flex items-center">
          <img
            alt="Image"
            class="mr-2 h-6 rounded-md"
            :src="
              'https://s3.us-west-1.amazonaws.com/privacyzen.media/favicons/' +
              exposureData.source.url +
              '.png'
            " />
          <h1>{{ exposureData.source.name }}</h1>
        </div>
      </template>
      <template #body>
        <template v-if="!exposureData.ignore">
          <AppNotification
            variant="info"
            v-if="exposureData.status === 'In Progress'">
            <template #icon>
              <ClockIcon class="flex-shrink-0 w-5 h-5" />
            </template>
            <template #message>
              We're working on removing your data right now.
            </template>
          </AppNotification>
          <AppNotification
            variant="warning"
            v-if="exposureData.status === 'Escalated'">
            <template #icon>
              <ChatBubbleLeftIcon class="flex-shrink-0 w-5 h-5" />
            </template>
            <template #message>
              We've escalated your removal request. When they respond, we'll let
              you know.
            </template>
          </AppNotification>
          <AppNotification
            variant="success"
            v-if="exposureData.status === 'Removed'">
            <template #icon>
              <TrashIcon class="flex-shrink-0 w-5 h-5" />
            </template>
            <template #message>
              Your data has been removed. We'll continue to monitor in-case it
              reappears.
            </template>
          </AppNotification>
        </template>
        <!-- temporarily disabled -->
        <div class="mb-4">
          <h3 class="text-xl font-semibold mb-2">
            What is {{ exposureData.source.name }}?
          </h3>
          <template v-if="exposureData.source.privacyLink">
            <p class="text-gray-500 mb-2">
              {{ exposureData.source.description }} You can view their
              <a
                target="_blank"
                :href="'https://' + exposureData.source.privacyLink"
                class="font-medium text-blue-600 hover:underline">
                <span style="display: inline">privacy policy here</span>
                <span v-if="!exposureData.source.removalLink">.</span>
              </a>
              <span v-if="exposureData.source.removalLink">
                or
                <a
                  target="_blank"
                  :href="'https://' + exposureData.source.removalLink"
                  class="font-medium text-blue-600 hover:underline">
                  begin the removal process here.
                </a>
              </span>
              <template v-if="exposureData.source.contactInfo">
                <template v-if="exposureData.source.contactInfo.includes('@')">
                  For all other inquiries, you can contact their team at
                  {{ exposureData.source.contactInfo }}.
                </template>
                <template v-else>
                  For all other inquiries, you can
                  <a
                    target="_blank"
                    :href="'https://' + exposureData.source.contactInfo"
                    class="font-medium text-blue-600 hover:underline">
                    contact their team here.
                  </a>
                </template>
              </template>
            </p>
          </template>
        </div>
      </template>
      <template #footer>
        <div
          class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <router-link to="premium" tabindex="-1">
            <SubmitButton
              variant="success"
              :disabled="exposureData.ignore"
              v-if="!userStore.user.subscription.active">
              Opt Out For Me
            </SubmitButton>
          </router-link>
          <a :href="exposureData.url" target="_blank">
            <SubmitButton>View My Profile</SubmitButton>
          </a>
          <SubmitButton
            v-if="!exposureData.ignore"
            @click="setIgnore(exposureData.id, true)"
            variant="danger">
            I'm Not Listed
          </SubmitButton>
          <SubmitButton
            v-else
            @click="setIgnore(exposureData.id, false)"
            variant="info">
            Undo hide
          </SubmitButton>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRef,
  watch,
} from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import BaseModal from '@/components/modals/BaseModal.vue';
import AppNotification from '@/components/common/AppNotification.vue';
import VueMagnifier from '@websitebeaver/vue-magnifier';
import ExposureList from './ExposureList.vue';
import ExposureSkeleton from './ExposureSkeleton.vue';

import {
  TrashIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
} from '@heroicons/vue/24/solid';
import TableViewer from './TableViewer.vue';
import { FaceSmileIcon } from '@heroicons/vue/24/outline';
import SubmitButton from './forms/SubmitButton.vue';

export default defineComponent({
  name: 'ExposuresComponent',
  components: {
    BaseModal,
    AppNotification,
    VueMagnifier,
    ExposureList,
    ExposureSkeleton,
    TrashIcon,
    ClockIcon,
    ChatBubbleLeftIcon,
    TableViewer,
    FaceSmileIcon,
    SubmitButton,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  props: {
    selectedPersonId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      numExposures: 0,
      featureImageLoaded: false,
      detailsModal: false,
      exposureData: {
        id: '',
        status: '',
        screenshot: '',
        url: '',
        ignore: false,
        source: {
          name: '',
          url: '',
          description: '',
          contactInfo: '',
          privacyLink: '',
          removalLink: '',
        },
      },
      tableColumns: [
        { key: 'name', alias: 'Name', reference: 'source.name' },
        {
          key: 'exposureRisk',
          alias: 'Exposure Risk',
          reference: 'source.exposedFields',
        },
        { key: 'status', alias: 'Status', reference: 'status' },
        { key: 'directUrl', alias: 'Profile Link', reference: 'url' },
        { key: 'lastSeen', alias: 'Last Seen', reference: 'updatedAt' },
      ],
      perPage: 10,
    };
  },
  setup(props) {
    const ignoredExposureIds = ref([] as any);
    const selectedPersonIdRef = toRef(props, 'selectedPersonId');
    const userStore = useUserStore();
    const exposures = ref([] as any);
    const googleResults = ref([] as any);
    const exposuresLoadedRef = ref(false);

    const getExposures = async () => {
      const response = await userStore.getExposures();
      exposures.value = response.exposures;
      googleResults.value = response.googleResults;
      exposuresLoadedRef.value = true;
    };

    onMounted(async () => {
      await getExposures();
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });

    watch(selectedPersonIdRef, async (newId) => {
      exposures.value = (await userStore.getExposures(newId)).exposures;
    });

    return {
      exposures,
      googleResults,
      ignoredExposureIds,
      selectedPersonIdRef,
      exposuresLoadedRef,
    };
  },
  methods: {
    showFeaturedImage() {
      this.featureImageLoaded = true;
    },
    showDetails(exposureData: any) {
      this.exposureData = exposureData;
      this.detailsModal = true;
      document.body.classList.add('modal-open');
    },
    exposuresLoaded(exposures: any) {
      const count = exposures
        .map((item: any) => (item.ignore === false ? 1 : 0))
        .reduce((acc: any, curr: any) => acc + curr, 0);
      this.numExposures = count;
    },
    setIgnore(id: string, ignore: boolean) {
      const index = this.ignoredExposureIds.findIndex(
        (obj: any) => obj.id === id,
      );
      if (index > -1) {
        const updatedObj = reactive({
          ...this.ignoredExposureIds[index],
          ignore,
        });
        this.ignoredExposureIds[index] = updatedObj;
      } else {
        this.ignoredExposureIds.push({
          id,
          ignore,
        });
      }
      if (ignore) {
        this.numExposures -= 1;
      } else {
        this.numExposures += 1;
      }
      this.userStore.ignoreExposure({
        id,
        ignore,
      });
      this.closeDetails();
    },
    closeDetails() {
      this.featureImageLoaded = false;
      this.detailsModal = false;
      document.body.classList.remove('modal-open');
    },
    formatDate(dateStr: string) {
      const date = new Date(dateStr);
      const options: any = {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      const formattedDate = date.toLocaleString('en-US', options);
      const day = date.getDate();
      const suffix =
        day % 10 === 1 && day !== 11
          ? 'st'
          : day % 10 === 2 && day !== 12
          ? 'nd'
          : day % 10 === 3 && day !== 13
          ? 'rd'
          : 'th';

      return formattedDate.replace(/\b(\d+)\b/, '$1' + suffix);
    },
  },
});
</script>
