<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 break-inside-avoid-page">
    <div class="duration-200 ease-linear">
      <div class="flex items-center mb-4 text-lg font-medium text-teal-500">
        <svg
          class="w-6 h-6 mr-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 20">
          <path
            d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z" />
        </svg>
        Tips on keeping your personal data private
      </div>
      <p class="mb-4 text-gray-500 dark:text-gray-400">
        Safeguarding your privacy is paramount in preventing your personal
        information from being sold and used by bad actors.
      </p>
      <p class="mb-4 text-lg font-medium text-gray-900 dark:text-white">
        In general...
      </p>
      <ul
        role="list"
        class="pl-2 mb-4 space-y-3 text-gray-500 list-disc dark:text-gray-400">
        <li class="flex space-x-2">
          <!-- Icon -->
          <svg
            class="flex-shrink-0 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"></path>
          </svg>
          <span class="leading-tight">
            Regularly review and adjust the privacy settings on your social
            media accounts and other online services. Limit the visibility of
            your posts and personal information to only those you trust.
          </span>
        </li>
        <li class="flex space-x-2">
          <!-- Icon -->
          <svg
            class="flex-shrink-0 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"></path>
          </svg>
          <span class="leading-tight">
            Be cautious when granting permissions to apps. Only provide
            necessary permissions and avoid granting access to sensitive data.
          </span>
        </li>
        <li class="flex space-x-2">
          <!-- Icon -->
          <svg
            class="flex-shrink-0 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"></path>
          </svg>
          <span class="leading-tight">
            When creating public profiles on social media, directories, or
            professional networking sites, avoid sharing overly sensitive
            personal details.
          </span>
        </li>
        <li class="flex space-x-2">
          <!-- Icon -->
          <svg
            class="flex-shrink-0 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"></path>
          </svg>
          <span class="leading-tight">
            Before signing up for a new service, take the time to review their
            privacy policy. Look for information about data sharing, third-party
            partnerships, and data selling practices. If the privacy policy
            indicates that they sell user data or share it with third parties
            without your consent, consider using an alternative service that
            respects your privacy.
          </span>
        </li>
        <li class="flex space-x-2">
          <!-- Icon -->
          <svg
            class="flex-shrink-0 w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"></path>
          </svg>
          <span class="leading-tight">
            Explore services like
            <span class="text-blue-500 font-bold">PrivacyZen</span>
            that monitor your personal data online. These tools help identify
            and eliminate exposures, as well as offer guidance to minimize risks
            and enhance your privacy efforts.
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrivacyTips',
});
</script>
