<template>
  <apexchart
    type="pie"
    height="200px"
    :options="removalChartOptions"
    :series="removalSeries"></apexchart>
</template>

<script lang="ts">
import { useUserStore } from '@/store/user.store';
import { defineComponent, ref, toRef, watch } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: 'RemovalsPieChart',
  components: {
    apexchart: VueApexCharts,
  },
  emits: ['data-changed'],
  props: {
    selectedPersonId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      polling: null as ReturnType<typeof setInterval> | null,
      removalChartOptions: {
        labels: ['In Progress', 'Removed', 'Escalated'],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  async mounted() {
    await this.getExposureChartData();
    /* Update pie chart every 30mins if no refresh */
    this.polling = setInterval(async () => {
      await this.getExposureChartData();
    }, 1.8e6);
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
  setup(props, { emit }) {
    const selectedPersonIdRef = toRef(props, 'selectedPersonId');
    const removalSeries: any = ref([]);

    const getExposureChartData = async () => {
      const countValue = (arr: any, key: string, value: string) =>
        arr.filter((x: any) => x[key] === value && !x['ignore']).length;
      const userStore = await useUserStore();
      const exposures = (
        await userStore.getExposures(selectedPersonIdRef.value)
      ).exposures;
      if (exposures.length > 0) {
        const inProgress = countValue(exposures, 'status', 'In Progress');
        const removed = countValue(exposures, 'status', 'Removed');
        const escalated = countValue(exposures, 'status', 'Escalated');
        removalSeries.value = [inProgress, removed, escalated];
      }
      emit('data-changed', exposures);
    };

    watch(selectedPersonIdRef, async () => {
      await getExposureChartData();
    });

    return { getExposureChartData, removalSeries, selectedPersonIdRef };
  },
});
</script>

<style>
.apexcharts-legend {
  justify-content: center !important;
  top: 0 !important;
}
.apexcharts-legend-text {
  font-size: 14px !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  --tw-text-opacity: 1;
}
</style>
