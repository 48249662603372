import axios, { AxiosInstance } from 'axios';
import { useAuthStore } from '@/store/auth.store';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

// Request interceptor for API calls
apiClient.interceptors.request.use((config) => {
  if (config.url !== 'auth/refresh') {
    const { access_token, refresh_token } = useAuthStore();
    if (access_token && refresh_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
      config.headers.Accept = 'application/json';
    }
  }
  return config;
});

// Response interceptor for API calls
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const authStore = useAuthStore();
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== 'auth/refresh'
    ) {
      originalRequest._retry = true;
      const accessToken = await authStore.refreshTokens();
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default apiClient;
