import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import mitt from 'mitt';
import router from './router';
import VueApexCharts from 'vue3-apexcharts';
// import VueGtag from 'vue-gtag';
import { createGtm } from '@gtm-support/vue-gtm';
import './index.css';

const app = createApp(App);
const emitter = mitt();

app.provide('emitter', emitter);
app.use(createPinia());
app.use(VueApexCharts);
app.use(router);
app.use(
  createGtm({
    id: 'GTM-56VBP4TS', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  }),
);
app.mount('#app');
