<template>
  <TransitionRoot
    class="z-20"
    :show="showBackdrop"
    enter="transition-opacity duration-200"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-200"
    leave-from="opacity-100"
    leave-to="opacity-0">
    <div
      class="fixed inset-0 bg-gray-900 opacity-40"
      @click="toggleSidebar"></div>
  </TransitionRoot>
  <aside
    ref="drawerRef"
    class="fixed inset-y-0 z-20 pt-14 flex-shrink-0 w-64 h-screen h-[calc(100dvh)] lg:sticky lg:translate-x-0 transition-transform -translate-x-full bg-white border-r"
    aria-label="Sidenav"
    id="drawer-navigation">
    <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
      <ul class="space-y-2">
        <component
          class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          active-class="text-primary-600"
          :class="{ 'opacity-25 cursor-default': link.comingSoon }"
          v-for="(link, i) in menu"
          :to="link.href"
          :key="i"
          :is="link.comingSoon ? 'span' : 'router-link'">
          <component
            :is="link.icon"
            :class="{
              'text-primary-600 group-hover:text-primary-600':
                $route.path === link.href,
              '!text-green-500 group-hover:text-green-600':
                userStore.user.subscription.active && link.title == 'Premium',
            }"
            class="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900" />
          <span
            class="ml-3"
            :class="{
              'text-green-500 group-hover:text-green-600':
                userStore.user.subscription.active && link.title == 'Premium',
            }">
            {{ link.title }}
            <span
              v-if="link.comingSoon"
              class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
              Coming Soon
            </span>
          </span>
        </component>
      </ul>
    </div>
    <div
      class="absolute bottom-0 left-0 justify-center px-4 pb-4 w-full"
      sidebar-bottom-menu>
      <div
        v-if="userStore.subscription.active"
        id="referral-widget"
        class="p-4 mb-4 bg-gray-50 rounded-lg"
        role="alert">
        <div class="flex justify-between items-center mb-3">
          <span
            class="bg-primary-100 text-primary-600 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
            Referral Bonus
          </span>
        </div>
        <div class="mb-3 text-sm text-gray-700 dark:text-gray-300">
          Receive a free month for each person you refer when they sign up for
          premium.
        </div>
        <div class="flex justify-between mb-2">
          <span class="text-sm font-medium text-blue-600 dark:text-white">
            Referrals
          </span>
          <span class="text-sm font-medium text-blue-600 dark:text-white">
            {{ userStore.user.referrals }}/10
          </span>
        </div>
        <div
          class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
          <div
            class="bg-blue-700 h-2.5 rounded-full"
            :style="{
              width: (userStore.user.referrals / 10) * 100 + '%',
            }"></div>
        </div>
        <span
          class="text-sm font-medium text-blue-600 cursor-pointer"
          @click="copyURL">
          <transition name="slide-fade" mode="out-in">
            <div
              :key="copyInviteLinkText"
              class="inline-flex items-center arrow-container hover:arrow-transition">
              {{ copyInviteLinkText }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 ml-1">
                <path
                  fill-rule="evenodd"
                  d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z"
                  clip-rule="evenodd" />
              </svg>
            </div>
          </transition>
        </span>
      </div>
      <a href="logout">
        <SubmitButton class="w-full">
          <ArrowRightOnRectangleIcon class="w-5 h-5 mr-1" />
          Logout
        </SubmitButton>
      </a>
      <p class="mt-4 text-sm text-center text-gray-500">
        © 2023 —
        <a
          href="https://privacyzen.com/"
          class="hover:underline"
          target="_blank">
          PrivacyZen
        </a>
      </p>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from 'vue';
import {
  ClipboardDocumentCheckIcon,
  Cog6ToothIcon,
  FaceSmileIcon,
  GlobeAltIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/24/solid';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import { Drawer } from 'flowbite';
import { TransitionRoot } from '@headlessui/vue';
import SubmitButton from './forms/SubmitButton.vue';
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'SidebarComponent',
  components: {
    ClipboardDocumentCheckIcon,
    Cog6ToothIcon,
    FaceSmileIcon,
    GlobeAltIcon,
    ShieldCheckIcon,
    TransitionRoot,
    SubmitButton,
    ArrowRightOnRectangleIcon,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  data() {
    return {
      menu: [
        {
          href: '/home',
          title: 'Home',
          icon: GlobeAltIcon,
        },
        {
          href: '/settings',
          title: 'Manage Profiles',
          icon: Cog6ToothIcon,
        },
        {
          href: '/reports',
          title: 'Reports',
          comingSoon: true,
          icon: ClipboardDocumentCheckIcon,
        },
        /* {
          href: '/family',
          title: 'Manage Family',
          icon: FaceSmileIcon,
        }, */
        {
          href: '/premium',
          title: 'Premium',
          icon: ShieldCheckIcon,
        },
        /*
        {
          href: '/report',
          title: 'Custom Removal',
          icon: TrashIcon,
        },
        */
      ],
    };
  },
  setup() {
    const userStore = useUserStore();
    const emitter: any = inject('emitter');
    const drawer = ref<Drawer | null>(null);
    const drawerRef = ref(null);
    const showBackdrop = ref(false);
    const copyInviteLinkText = ref('Copy Invite Link');

    onMounted(async () => {
      drawer.value = new Drawer(drawerRef.value, {
        bodyScrolling: true,
        placement: 'left',
        backdrop: false,
      });
      await userStore.getDetails();
    });

    const toggleSidebar = () => {
      drawer.value?.toggle();
      showBackdrop.value = !showBackdrop.value;
    };

    emitter.on('toggle-sidebar', () => {
      toggleSidebar();
    });

    const copyURL = () => {
      navigator.clipboard.writeText(
        `https://privacyzen.com/?promo_code=${userStore.user.referralCode}`,
      );
      copyInviteLinkText.value = 'Link Copied';

      // Set a timeout to reset the button text after 2 seconds
      setTimeout(() => {
        copyInviteLinkText.value = 'Copy Invite Link';
      }, 2000);
    };

    return {
      toggleSidebar,
      drawerRef,
      copyInviteLinkText,
      showBackdrop,
      copyURL,
    };
  },
});
</script>

<style scoped>
/* styles.css */
.arrow-container {
  transition: transform 0.3s ease-in-out; /* Apply transition to the transform property */
}
.arrow-container:hover {
  transform: translateX(
    10px
  ); /* Move the arrow to the right by 10px on hover */
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
