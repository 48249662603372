<template>
  <div class="mb-4 bg-white border border-gray-200 rounded-lg shadow-sm">
    <div class="border-b border-gray-200 p-4 flex">
      <div class="flex items-center">
        <div>
          <p class="text-sm text-gray-500">Current Plan</p>
          <h3 class="text-xl font-semibold flex items-center">
            {{
              (userStore.subscription.active &&
                userStore.subscription.planName) ||
              'Free'
            }}
            <!--<span
              v-if="!userStore.subscription.active"
              class="ml-2 text-xs bg-gray-100 text-gray-700 font-medium inline-flex items-center px-1 py-0.5 rounded">
              Canceled
            </span>-->
            <span
              v-if="
                userStore.subscription.active &&
                !userStore.subscription.cancelAtPeriodEnd
              "
              class="ml-2 text-xs bg-green-100 text-green-800 font-medium inline-flex items-center px-1 py-0.5 rounded">
              Active
            </span>
            <span
              v-else-if="userStore.subscription.cancelAtPeriodEnd"
              class="ml-2 text-xs bg-gray-100 text-gray-800 font-medium inline-flex items-center px-1 py-0.5 rounded">
              <ClockIcon class="w-4 mr-1" />
              Cancels
              {{ userStore.subscription.nextBillingDate.split(',')[0] }}
            </span>
          </h3>
        </div>
      </div>
      <div class="sm:inline-flex ml-auto flex flex-wrap justify-end">
        <SubmitButton
          @click="showDrawer"
          :loading="userStore.pollingForNewPlan"
          :disabled="userStore.pollingForNewPlan"
          class="w-32 h-[38px] px-5 m-2">
          Change Plan
        </SubmitButton>
        <SubmitButton
          v-if="
            userStore.subscription.active &&
            !userStore.subscription.cancelAtPeriodEnd
          "
          @click="openCancelPlanModal"
          :loading="cancelLoading"
          :disabled="userStore.pollingForNewPlan || cancelLoading"
          variant="danger"
          class="w-32 h-[38px] px-5 m-2">
          Cancel Plan
        </SubmitButton>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="p-4">
        <p class="text-sm text-gray-500">Next Billing</p>
        <h3 class="text-md font-semibold">
          <span v-if="userStore.subscription.active">
            {{ userStore.subscription.nextBillingDate }}
          </span>
          <span v-else>N/A</span>
        </h3>
      </div>
      <div class="p-4">
        <p class="text-sm text-gray-500">Family Members</p>
        <template
          v-if="
            userStore.subscription.active &&
            userStore.subscription.planName == 'Family'
          ">
          <h3 class="text-md font-semibold">
            {{ userStore.user.familyMembers.length }}/{{
              userStore.user.subscription.maxFamilyMembers
            }}
          </h3>
        </template>
        <template v-else>
          <h3 class="text-md font-semibold text-blue-700">
            Unlock with Premium
          </h3>
        </template>
      </div>
    </div>
  </div>
  <!-- drawer component -->
  <div
    ref="drawerRef"
    id="planDawer"
    class="fixed top-0 right-0 z-20 mt-14 border-l border-gray-200 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-96 overflow-y-scroll h-[calc(100vh_-_50px)]"
    tabindex="-1">
    <h5
      id="drawer-right-label"
      class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-4 h-4 mr-2.5">
        <path
          fill-rule="evenodd"
          d="M9.661 2.237a.531.531 0 01.678 0 11.947 11.947 0 007.078 2.749.5.5 0 01.479.425c.069.52.104 1.05.104 1.59 0 5.162-3.26 9.563-7.834 11.256a.48.48 0 01-.332 0C5.26 16.564 2 12.163 2 7c0-.538.035-1.069.104-1.589a.5.5 0 01.48-.425 11.947 11.947 0 007.077-2.75zm4.196 5.954a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
          clip-rule="evenodd" />
      </svg>
      Select a new plan
    </h5>
    <button
      type="button"
      @click="hideDrawer"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
      <svg
        aria-hidden="true"
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <ul class="grid w-full gap-2 md:grid-cols-2 mb-4">
      <li>
        <input
          type="radio"
          id="month"
          value="month"
          class="hidden peer"
          v-model="billingInterval" />
        <label
          for="month"
          class="inline-flex items-center justify-center w-full border border-opacity-100 border-gray-200 outline-transparent text-gray-800 rounded-md py-2.5 text-sm font-medium shadow-sm cursor-pointer outline outline-2 peer-checked:outline-blue-600 peer-checked:text-blue-600 peer-checked:border-opacity-0 hover:text-gray-600 hover:bg-gray-100 text-center">
          Monthly
        </label>
      </li>
      <li>
        <input
          type="radio"
          id="year"
          value="year"
          class="hidden peer"
          v-model="billingInterval" />
        <label
          for="year"
          class="inline-flex items-center justify-center w-full border border-opacity-100 border-gray-200 outline-transparent text-gray-800 rounded-md py-2.5 text-sm font-medium shadow-sm cursor-pointer outline outline-2 peer-checked:outline-blue-600 peer-checked:text-blue-600 peer-checked:border-opacity-0 hover:text-gray-600 hover:bg-gray-100 text-center">
          Yearly
        </label>
      </li>
    </ul>
    <Transition name="fade" mode="out-in" appear>
      <!-- month -->
      <ul
        class="grid w-full gap-2 md:grid-cols-1"
        v-if="billingInterval == 'month'">
        <li
          v-for="availablePlan in availablePlans.monthly"
          :key="availablePlan.planName">
          <input
            type="radio"
            :id="availablePlan.id"
            :value="{
              planName: availablePlan.planName,
              interval: 'month',
              cost: availablePlan.cost,
            }"
            v-model="plan"
            class="hidden peer" />
          <PlanCard
            :for-input="availablePlan.id"
            :name="availablePlan.planName"
            :price="'$' + availablePlan.cost"
            interval="mo">
            <template #description>
              <p class="text-sm text-gray-500">
                {{ availablePlan.description }}
              </p>
            </template>
          </PlanCard>
        </li>
      </ul>
      <!-- year -->
      <ul class="grid w-full gap-2 md:grid-cols-1" v-else>
        <li
          v-for="availablePlan in availablePlans.yearly"
          :key="availablePlan.planName">
          <input
            type="radio"
            :id="availablePlan.id"
            :value="{
              planName: availablePlan.planName,
              interval: 'year',
              cost: availablePlan.cost,
            }"
            v-model="plan"
            class="hidden peer" />
          <PlanCard
            :for-input="availablePlan.id"
            :name="availablePlan.planName"
            :price="'$' + availablePlan.cost"
            interval="yr">
            <template #badge v-if="availablePlan.discount">
              <Badge type="yellow" class="ml-2 px-2.5 py-0.5 rounded-full">
                {{ availablePlan.discount }}% off
              </Badge>
            </template>
            <template #description>
              <p class="text-sm text-gray-500">
                {{ availablePlan.description }}
              </p>
            </template>
          </PlanCard>
        </li>
      </ul>
    </Transition>
    <div class="mt-4 mb-4">
      <template v-if="!proratedLoading">
        <p class="text-sm text-gray-500">
          {{ proratedMessage }}
        </p>
      </template>
      <template v-else>
        <div
          class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 animate-pulse"></div>
      </template>
    </div>
    <SubmitButton
      class="w-full h-10 t-4"
      @click="changePlan"
      :loading="upgradeLoading"
      :disabled="
        !plan ||
        proratedLoading ||
        upgradeLoading ||
        (userStore.subscription.active &&
          plan.planName == userStore.subscription.planName &&
          plan.interval == userStore.subscription.interval &&
          !userStore.subscription.cancelAtPeriodEnd)
      ">
      {{
        (!userStore.subscription.active && 'Proceed to Payment') ||
        'Change plan'
      }}
    </SubmitButton>
  </div>
  <ConfirmModal
    ref="cancelPlanModal"
    @confirm="cancelPlan"
    confirm="Yes, cancel"
    cancel="Nevermind"
    title="Cancel Plan"
    description="Are you sure you want to
  cancel your plan? You'll have access to premium benefits until the next billing cycle." />
</template>

<script lang="ts">
import {
  Suspense,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useUserStore } from '@/store/user.store';
import { useStripeStore } from '@/store/stripe.store';
import { ChangePlanForm } from '@/models/user.model';
import { Drawer } from 'flowbite';
import { Badge } from 'flowbite-vue';
import SubmitButton from '../forms/SubmitButton.vue';
import PlanCard from './PlanCard.vue';
import ConfirmModal from '../modals/ConfirmModal.vue';
import { ClockIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  name: 'PlanInfo',
  components: {
    ConfirmModal,
    SubmitButton,
    PlanCard,
    Badge,
    ClockIcon,
  },
  setup() {
    const userStore = useUserStore();
    const stripeStore = useStripeStore();
    const drawer = ref<Drawer | null>(null);
    const availablePlans = ref<any>([]);
    const plan = ref<ChangePlanForm | null>(null);
    const cancelPlanModal = ref();
    const drawerRef = ref(null);
    const billingInterval = ref('month');
    const proratedMessage = ref('');
    const proratedLoading = ref(false);
    const upgradeLoading = ref(false);
    const cancelLoading = ref(false);

    onMounted(async () => {
      availablePlans.value = await userStore.getAvailablePlans();
      drawer.value = new Drawer(drawerRef.value, {
        placement: 'right',
        backdropClasses: 'fixed inset-0 bg-gray-900 opacity-40 z-10',
      });
      await userStore.getDetails();
    });

    onUnmounted(() => {
      hideDrawer();
    });

    /* fires when billing interval is changed */
    watch(billingInterval, (interval) => {
      return interval;
    });

    watch(plan, async () => {
      if (plan.value?.planName && userStore.subscription.active) {
        /*
        proratedLoading.value = true;
        const { amount_due } = await stripeStore.upcomingInvoice(plan.value);
        proratedLoading.value = false;
        */
        proratedMessage.value =
          "Your subscription will be prorated for the remaining days in this billing period. Today, you'll only be charged the prorated difference.";
      } else {
        proratedMessage.value = '';
      }
    });

    const changePlan = async () => {
      upgradeLoading.value = true;
      if (!userStore.subscription.active) {
        if (plan.value) {
          userStore.previewPlan = plan.value;
        }
      } else {
        if (plan.value) {
          await stripeStore.updateSubscription({
            planName: plan.value.planName as string,
            interval: plan.value.interval as string,
          });
        }
        await userStore.pollSubscription();
      }
      hideDrawer();
    };

    const cancelPlan = async () => {
      cancelLoading.value = true;
      await stripeStore.cancelSubscription();
      await userStore.pollSubscription();
      // userStore.paymentMethods = [];
      cancelLoading.value = false;
    };

    const hideDrawer = () => {
      drawer.value?.hide();
    };

    const showDrawer = async () => {
      upgradeLoading.value = false;
      await userStore.getDetails();
      if (userStore.subscription.active) {
        plan.value = {
          planName: userStore.subscription.planName as string,
          interval: userStore.subscription.interval as string,
          cost: userStore.subscription.cost as number,
        };
      }
      drawer.value?.show();
    };

    const openCancelPlanModal = () => {
      if (cancelPlanModal.value) {
        cancelPlanModal.value.openModal();
      }
    };

    return {
      cancelPlanModal,
      billingInterval,
      drawerRef,
      proratedMessage,
      proratedLoading,
      upgradeLoading,
      cancelLoading,
      userStore,
      plan,
      availablePlans,
      Suspense,
      PlanCard,
      changePlan,
      cancelPlan,
      hideDrawer,
      showDrawer,
      openCancelPlanModal,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
