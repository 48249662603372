<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <h3 class="mb-4 text-xl font-semibold dark:text-white">Payment Methods</h3>
    <template v-if="userStore.paymentMethods.length > 0 || loadingCards">
      <ul
        class="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <PaymentMethodsSkeleton v-if="loadingCards" />
        <template v-else>
          <li
            v-for="(card, index) in userStore.paymentMethods"
            :key="card.id"
            :class="{
              'border-b border-gray-200':
                index != userStore.paymentMethods.length - 1,
            }"
            class="flex items-center pl-4 rounded dark:border-gray-700">
            <input
              id="bordered-radio-1"
              type="radio"
              value=""
              name="bordered-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              :checked="card.active"
              @change="activateCard(card.id)" />
            <label
              for="bordered-radio-1"
              class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              <img
                class="w-8 inline"
                :src="require('@/assets/cards/' + card.brand + '.svg')" />
              <span class="ml-2">•••• •••• •••• {{ card.last4 }}</span>
              <span class="text-sm text-gray-500 ml-2">
                Expiry {{ card.expMonth }}/{{ card.expYear }}
              </span>
            </label>
            <button
              @click="detachPaymentMethod(card.id)"
              :disabled="card.active"
              type="button"
              class="text-slate-500 hover:text-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 disabled:text-slate-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>

              <span class="sr-only">Delete card</span>
            </button>
          </li>
        </template>
      </ul>
      <SubmitButton
          @click="showAddCard"
          v-if="userStore.paymentMethods.length > 0"
          class="w-32 h-[38px] px-5 mt-2">
          Add Card
        </SubmitButton>
    </template>
    <template v-else>
      <div class="text-center">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </svg>

        <h3 class="mt-2 text-sm font-semibold text-gray-900">
          No payment methods
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Add a payment method to upgrade your plan
        </p>
        <div class="mt-6">
          <button
            @click="showAddCard"
            type="button"
            class="inline-flex items-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
            <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Card
          </button>
        </div>
      </div>
    </template>
  </div>
  <!-- drawer component -->
  <div
    ref="drawerRef"
    class="fixed top-0 right-0 z-20 mt-14 border-l border-gray-200 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80"
    tabindex="-1">
    <h5
      id="drawer-right-label"
      class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 mr-2.5">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
      </svg>

      Add a new card
    </h5>
    <button
      type="button"
      @click="hideAddCard()"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <form @submit.prevent="addPaymentMethod">
      <div
        id="card-element"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></div>
      <p class="mt-2 text-sm text-red-600">{{ cardError }}</p>
      <SubmitButton
        type="submit"
        class="w-full h-10 mt-4"
        :loading="isSubmittingCard"
        :failed="addCardFailed"
        :input-errors="Object.keys(cardError).length > 0">
        Save card
      </SubmitButton>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { useStripeStore } from '@/store/stripe.store';
import { useUserStore } from '@/store/user.store';
import { Drawer } from 'flowbite';
import {
  Stripe,
  StripeCardElement,
  StripeElements,
  loadStripe,
} from '@stripe/stripe-js';
import { PlusIcon } from '@heroicons/vue/20/solid';
import PaymentMethodsSkeleton from './PaymentMethodsSkeleton.vue';
import SubmitButton from '../forms/SubmitButton.vue';

export default defineComponent({
  name: 'PaymentMethods',
  components: {
    SubmitButton,
    PaymentMethodsSkeleton,
    PlusIcon,
  },
  setup() {
    const userStore = useUserStore();
    const stripeStore = useStripeStore();
    const drawer = ref<Drawer | null>(null);
    const loadingCards = ref(true);
    const isSubmittingCard = ref(false);
    const addCardFailed = ref(false);
    const drawerRef = ref(null);
    const cardError = ref('');
    const stripe = ref<Stripe | null>(null);
    const elements = ref<StripeElements>({} as StripeElements);
    const cardElement = ref<StripeCardElement>({} as StripeCardElement);

    onMounted(async () => {
      drawer.value = new Drawer(drawerRef.value, {
        placement: 'right',
        backdropClasses: 'fixed inset-0 bg-gray-900 opacity-40 z-10',
      });

      const { publishableKey } = await stripeStore.getConfig();
      stripe.value = await loadStripe(publishableKey);

      if (stripe.value) {
        await refreshCards();
        elements.value = stripe.value.elements();
        cardElement.value = elements.value.create('card', {
          disableLink: true,
        });
        try {
          cardElement.value.mount('#card-element');
        } catch (_) {
          // card element not mounted, usually because user navigated away
          return;
        }
      }
    });

    const refreshCards = async () => {
      if (stripe.value) {
        const customer = await stripeStore.getCustomer();
        const paymentMethods = await stripeStore.getPaymentMethods();
        loadingCards.value = false;
        for (const paymentMethod of paymentMethods) {
          const checkId = (obj: any) => obj.id === paymentMethod.id;
          // If the payment method doesn't already exist
          if (!userStore.paymentMethods.some(checkId)) {
            userStore.paymentMethods.push({
              id: paymentMethod.id,
              brand: paymentMethod.card.brand,
              expMonth: paymentMethod.card.exp_month,
              expYear: paymentMethod.card.exp_year,
              last4: paymentMethod.card.last4,
              active:
                paymentMethod.id ==
                customer.invoice_settings.default_payment_method,
            });
          } else {
            userStore.paymentMethods = userStore.paymentMethods.map((x) =>
              x.id === paymentMethod.id
                ? {
                    ...x,
                    active:
                      paymentMethod.id ==
                      customer.invoice_settings.default_payment_method,
                  }
                : x,
            );
          }
        }
      }
    };

    const activateCard = async (cardId: string) => {
      const { paymentMethodId } = await stripeStore.setDefaultPaymentMethod(
        cardId.toString(),
      );
      userStore.paymentMethods = userStore.paymentMethods.map((obj) => ({
        ...obj,
        active: obj.id == paymentMethodId,
      }));
    };

    const addPaymentMethod = async () => {
      if (stripe.value) {
        isSubmittingCard.value = true;
        cardElement.value.update({ disabled: true });
        const { paymentMethod, error } = await stripe.value.createPaymentMethod(
          {
            type: 'card',
            card: cardElement.value,
            billing_details: {},
          },
        );
        if (error) {
          if (error instanceof Error) {
            cardError.value = error.message;
          }
          await failAddCard();
        } else {
          try {
            const attachPaymentMethod = await stripeStore.attachPaymentMethod(
              paymentMethod.id as string,
            );
            const attachedCard = attachPaymentMethod.data;
            userStore.paymentMethods.unshift({
              id: attachedCard.id,
              brand: attachedCard.card.brand,
              expMonth: attachedCard.card.exp_month,
              expYear: attachedCard.card.exp_year,
              last4: attachedCard.card.last4,
              active: true,
            });
            userStore.paymentMethods = userStore.paymentMethods.map((obj) => ({
              ...obj,
              active: obj.id == attachedCard.id,
            }));
            drawer.value?.hide();
            cardElement.value.clear();
            isSubmittingCard.value = false;
          } catch (error: any) {
            if (error && error.response) {
              cardError.value = error.response.data.message;
            } else {
              console.log(error);
            }
            await failAddCard();
          }
        }
        cardElement.value.update({ disabled: false });
      }
    };

    const failAddCard = async () => {
      isSubmittingCard.value = false;
      addCardFailed.value = true;
      await new Promise((r) => setTimeout(r, 1000));
      addCardFailed.value = false;
    };

    const showAddCard = async () => {
      drawer.value?.show();
    };

    const hideAddCard = async () => {
      drawer.value?.hide();
    };

    const detachPaymentMethod = async (paymentMethodId: string) => {
      try {
        userStore.paymentMethods = userStore.paymentMethods.filter(
          (paymentMethods) => paymentMethods.id !== paymentMethodId,
        );
        await stripeStore.detachPaymentMethod(paymentMethodId);
      } catch (error: any) {
        if (error.response) {
          console.log(error.response.data.message);
        }
      }
    };

    onUnmounted(() => {
      hideAddCard();
    });

    // Use watchEffect to watch for changes on userStore.subscription.plan and userStore.subscription.active
    watchEffect(async () => {
      // Since we are accessing both properties here, this will automatically track changes to them
      // and trigger the following block whenever either of them changes.
      // const { planName, active } = userStore.subscription;

      // You can perform any logic here based on the changes to the plan and active properties.
      // For example, you can refresh cards if either of these properties changes.
      await refreshCards();
    });

    return {
      userStore,
      drawerRef,
      cardError,
      loadingCards,
      isSubmittingCard,
      addCardFailed,
      showAddCard,
      hideAddCard,
      activateCard,
      detachPaymentMethod,
      addPaymentMethod,
    };
  },
});
</script>
