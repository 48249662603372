<template>
  <nav aria-label="Page navigation example">
    <ul class="inline-flex items-stretch -space-x-px cursor-pointer">
      <li>
        <button
          @click.prevent="goToPage(currentPage - 1)"
          :disabled="currentPage <= 1"
          class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-25">
          <span class="sr-only">Previous</span>
          <svg
            class="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 1 1 5l4 4" />
          </svg>
        </button>
      </li>
      <li
        v-for="page in pages"
        :key="page"
        :class="{
          'z-10': page === currentPage,
        }">
        <a
          @click.prevent="goToPage(page)"
          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          :class="{
            'text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700':
              page === currentPage,
            'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700':
              page !== currentPage,
          }">
          {{ page }}
        </a>
      </li>
      <li>
        <button
          @click.prevent="goToPage(currentPage + 1)"
          :disabled="currentPage >= totalPages"
          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-25">
          <span class="sr-only">Next</span>
          <svg
            class="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4" />
          </svg>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pages() {
      const pageCount = this.totalPages;
      const pages = [];

      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
  methods: {
    goToPage(page) {
      this.$emit('page-change', page);
    },
  },
};
</script>
