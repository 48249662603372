<template>
  <template v-if="!skeleton">
    <button
      type="button"
      class="w-full text-center inline-flex items-center p-4 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm px-5 py-2.5 transition-opacity disabled:opacity-25 opacity-100"
      :disabled="disabled"
      :class="{
        'border-2 border-blue-500 text-blue-600 focus:ring-blue-200 hover:bg-blue-200':
          active,
        'border-2 border-gray-200 text-gray-600': !active,
      }">
      <UserIcon
        class="w-6 rounded-full mr-2 -ml-1"
        :class="{
          'text-blue-600': active,
          'text-gray-400': !active,
        }" />
      {{ text }}
    </button>
  </template>
  <template v-else>
    <button
      type="button"
      class="w-full text-center inline-flex items-center p-4 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-gray-200 text-gray-600 animate-pulse">
      <UserIcon
        class="w-6 rounded-full mr-2 -ml-1"
        :class="{
          'text-blue-600': active,
          'text-gray-400': !active,
        }" />
      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UserIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { UserIcon },
});
</script>
