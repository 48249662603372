<template>
  <div class="flex antialiased bg-gray-50">
    <NavBar v-if="false" />
    <SidebarComponent v-if="false" />
    <div class="flex overflow-hidden bg-gray-50 w-full" v-if="report">
      <!-- flex pt-16 overflow-hidden bg-gray-50 pb-12 w-full -->
      <div
        id="main-content"
        class="relative w-full h-full overflow-y-auto bg-gray-50">
        <div
          class="grid grid-cols-1 px-4 pt-4 pb-4 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
          <!-- Right Content -->
          <div class="col-span-full xl:col-auto">
            <div
              class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 break-inside-avoid-page">
              <div class="duration-200 ease-linear">
                <div
                  class="flex items-center mb-4 text-lg font-medium text-primary-600">
                  <svg
                    class="w-6 h-6 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true">
                    <path
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      d="M9.664 1.319a.75.75 0 01.672 0 41.059 41.059 0 018.198 5.424.75.75 0 01-.254 1.285 31.372 31.372 0 00-7.86 3.83.75.75 0 01-.84 0 31.508 31.508 0 00-2.08-1.287V9.394c0-.244.116-.463.302-.592a35.504 35.504 0 013.305-2.033.75.75 0 00-.714-1.319 37 37 0 00-3.446 2.12A2.216 2.216 0 006 9.393v.38a31.293 31.293 0 00-4.28-1.746.75.75 0 01-.254-1.285 41.059 41.059 0 018.198-5.424zM6 11.459a29.848 29.848 0 00-2.455-1.158 41.029 41.029 0 00-.39 3.114.75.75 0 00.419.74c.528.256 1.046.53 1.554.82-.21.324-.455.63-.739.914a.75.75 0 101.06 1.06c.37-.369.69-.77.96-1.193a26.61 26.61 0 013.095 2.348.75.75 0 00.992 0 26.547 26.547 0 015.93-3.95.75.75 0 00.42-.739 41.053 41.053 0 00-.39-3.114 29.925 29.925 0 00-5.199 2.801 2.25 2.25 0 01-2.514 0c-.41-.275-.826-.541-1.25-.797a6.985 6.985 0 01-1.084 3.45 26.503 26.503 0 00-1.281-.78A5.487 5.487 0 006 12v-.54z"></path>
                  </svg>
                  Insights
                </div>
                <h3
                  class="mb-4 text-2xl font-medium text-gray-900 dark:text-white">
                  Hi, George 👋
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                  Welcome to your
                  <span class="font-bold">August, 2023</span>
                  report - a window into your digital footprint! Gain insights
                  into your online presence, and find fresh avenues to fortify
                  your privacy.
                </p>
                <a
                  v-if="false"
                  href="#"
                  class="inline-flex items-center p-2 font-medium rounded-lg text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
                  Get me there
                  <svg
                    class="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true">
                    <path
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"></path>
                  </svg>
                </a>
              </div>
            </div>
            <ExposuresLineChart :report="report" />
            <PrivacyTips />
          </div>
          <div class="col-span-2">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <ExposureCard
                v-for="exposure in report.exposures"
                :key="exposure.source.name"
                :exposure="exposure"
                class="break-inside-avoid-page"></ExposureCard>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { initFlowbite } from 'flowbite';
import NavBar from '@/components/NavBar.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import ExposuresLineChart from '@/components/HistoricalExposuresLineChart.vue';
import ExposureCard from '@/components/ExposureCard.vue';
import { useReportStore } from '@/store/report.store';
import PrivacyTips from '@/components/PrivacyTips.vue';

export default {
  name: 'ReportView',
  components: {
    NavBar,
    SidebarComponent,
    ExposuresLineChart,
    ExposureCard,
    PrivacyTips,
  },
  setup() {
    const route = useRoute();
    const reportStore = useReportStore();
    const reportId = route.params.id as string;
    const report = ref();

    onMounted(() => {
      initFlowbite();
    });

    onBeforeMount(async () => {
      const reportData = await reportStore.getReport(reportId);
      report.value = reportData;
    });

    return {
      report,
    };
  },
};
</script>
