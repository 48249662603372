<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 sm:p-6">
    <h3 class="mb-4 text-xl font-semibold dark:text-white">
      General Information
    </h3>
    <VeeForm
      :validation-schema="validationSchema"
      @submit="onSubmit"
      as="form"
      ref="settingsForm"
      :initial-values="defaultValues"
      v-slot="{ errorBag }">
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
          <span
            for="firstName"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            First name
          </span>
          <Field name="firstName" type="text" v-slot="{ field, errors }">
            <TextInput
              v-bind="field"
              type="text"
              :class="{
                'blur-sm animate-pulse': generalSettingsLoading,
              }"
              :failed="!!errors.length" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="firstName" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="lastName"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Last Name
          </label>
          <Field name="lastName" type="text" v-slot="{ field }">
            <TextInput
              v-bind="field"
              :class="{
                'blur-sm animate-pulse': generalSettingsLoading,
              }"
              type="text" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="lastName" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="city"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            City
          </label>
          <Field name="city" type="text" v-slot="{ field }">
            <PlaceAutocomplete
              v-bind="field"
              name="city"
              id="city"
              :class="{
                'blur-sm animate-pulse': generalSettingsLoading,
              }"
              @keydown.enter.prevent
              @localityChanged="localityChanged"
              autocomplete-id="city" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="city" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="birthdate"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Birthdate
          </label>
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <Field name="birthdate" type="text" v-slot="{ field }">
              <DateInput
                v-bind="field"
                name="birthdate"
                :class="{
                  'blur-sm animate-pulse': generalSettingsLoading,
                }"
                id="birthdate" />
            </Field>
          </div>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="birthdate" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Email
          </label>
          <Field name="email" type="text" v-slot="{ field }">
            <TextInput
              v-bind="field"
              type="email"
              name="email"
              :class="{
                'blur-sm animate-pulse': generalSettingsLoading,
              }"
              id="email"
              disabled />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="email" />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label
            for="phone"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Phone Number
          </label>
          <Field name="phone" type="text" v-slot="{ field }">
            <PhoneInput
              v-bind="field"
              name="phone"
              :class="{
                'blur-sm animate-pulse': generalSettingsLoading,
              }"
              id="phone" />
          </Field>
          <ErrorMessage
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            name="phone" />
        </div>
        <div class="col-span-6 sm:col-full flex items-center">
          <SubmitButton
            type="submit"
            :loading="updateUserStatus == 'loading'"
            :success="updateUserStatus == 'success'"
            :input-errors="Object.keys(errorBag).length > 0"
            class="w-40 h-10"
            :disabled="generalSettingsLoading">
            Update Settings
          </SubmitButton>
          <SubmitButton
            @click="openRemoveFamilyMemberModal"
            variant="danger"
            v-if="familyMemberIdRef"
            class="w-24 h-10">
            Remove
          </SubmitButton>
        </div>
      </div>
    </VeeForm>
  </div>
  <ConfirmModal
    ref="removeFamilyMemberModal"
    @confirm="deleteFamilyMember"
    confirm="Yes, remove"
    cancel="Nevermind"
    :title="`Remove ${defaultValues.firstName} ${defaultValues.lastName}?`"
    :description="`Are you sure you want to remove ${defaultValues.firstName} ${defaultValues.lastName} from your plan? You will still have access to their historical exposures.`" />
</template>

<script lang="ts">
import * as yup from 'yup';
import { defineComponent, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { TimeUtils } from '@/utils/time.util';
import { useUserStore } from '@/store/user.store';
import { ErrorMessage, Field, Form as VeeForm } from 'vee-validate';
import { SettingsForm } from '@/models/user.model';
import { getSubmit } from '@/utils/form-helpers';
import DateInput from '@/components/forms/DateInput.vue';
import PlaceAutocomplete from '@/components/forms/PlaceAutocomplete.vue';
import TextInput from '@/components/forms/TextInput.vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import SubmitButton from '@/components/forms/SubmitButton.vue';
import ConfirmModal from './modals/ConfirmModal.vue';

interface LocalityDict {
  city: string;
  state: string;
}

export default defineComponent({
  name: 'GeneralSettings',
  components: {
    DateInput,
    PlaceAutocomplete,
    TextInput,
    VeeForm,
    Field,
    ErrorMessage,
    PhoneInput,
    SubmitButton,
    ConfirmModal,
  },
  emits: [
    'startedUpdatingFamilyMember',
    'updatedFamilyMember',
    'deletedFamilyMember',
  ],
  setup(_, { emit }) {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const cityInput = ref('');
    const stateInput = ref('');
    const updateUserStatus = ref('');
    const familyMemberIdRef = ref();
    const removeFamilyMemberModal = ref();
    const generalSettingsLoading = ref();
    const defaultValues = ref();
    const settingsForm = ref();

    const validationSchema = yup.object({
      firstName: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Only letters are allowed')
        .required()
        .label('First name'),
      lastName: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Only letters are allowed')
        .required()
        .label('Last name'),
      city: yup.string().required().label('City'),
      birthdate: yup
        .string()
        .required()
        .matches(
          /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
          'Date format must be mm/dd/yyyy',
        )
        .label('Birthdate'),
      phone: yup
        .string()
        .notRequired()
        .test('phone-number', 'Invalid phone number', (value) => {
          if (!value) return true; // Allow empty value
          const numericValue = value.replace(/\D/g, ''); // Extract numeric characters
          return numericValue.length === 0 || numericValue.length === 10;
        })
        .label('Phone'),
    });

    defaultValues.value = {
      firstName: user.value.firstName,
      lastName: user.value.lastName,
      city: `${user.value.city}, ${user.value.state}`,
      birthdate: TimeUtils.formatDate(user.value.birthdate),
      email: user.value.email,
      phone: user.value.phone,
    };

    const refreshDefaultValues = async (familyMemberId: string) => {
      generalSettingsLoading.value = true;
      if (settingsForm.value) {
        settingsForm.value.resetForm();

        const newDefaultValues = (await userStore.getDetails(
          familyMemberId,
        )) as any;

        defaultValues.value.firstName = newDefaultValues.firstName;
        defaultValues.value.lastName = newDefaultValues.lastName;
        defaultValues.value.birthdate = TimeUtils.formatDate(
          newDefaultValues.birthdate,
        );
        defaultValues.value.city = `${newDefaultValues.city}, ${newDefaultValues.state}`;
        defaultValues.value.email = newDefaultValues.email;
        defaultValues.value.phone = newDefaultValues.phone;

        familyMemberIdRef.value = familyMemberId;
      }
      generalSettingsLoading.value = false;
    };

    const localityChanged = (dict: LocalityDict) => {
      const { city, state } = dict;
      cityInput.value = city;
      stateInput.value = state;
    };

    const openRemoveFamilyMemberModal = () => {
      if (removeFamilyMemberModal.value) {
        removeFamilyMemberModal.value.openModal();
      }
    };

    const onSubmit = getSubmit(validationSchema, (values) => {
      emit('startedUpdatingFamilyMember');
      updateUserStatus.value = 'loading';
      const data: SettingsForm = {
        firstName: values.firstName,
        lastName: values.lastName,
        city: cityInput.value || userStore.user.city,
        state: stateInput.value || userStore.user.state,
        birthdate: values.birthdate,
        phone: values.phone,
      };
      const familyMemberId = familyMemberIdRef.value || null;
      userStore.setUserDetails(data, familyMemberId).then(async () => {
        await new Promise((r) => setTimeout(r, 1000));
        emit('updatedFamilyMember', {
          familyMemberId: familyMemberId,
          firstName: values.firstName,
          lastName: values.lastName,
        });
        updateUserStatus.value = 'success';
        await new Promise((r) => setTimeout(r, 1000));
        updateUserStatus.value = '';
      });
    });

    const deleteFamilyMember = async () => {
      if (familyMemberIdRef.value) {
        await userStore.deleteFamilyMember(familyMemberIdRef.value);
        emit('deletedFamilyMember', familyMemberIdRef.value);
      }
    };

    return {
      updateUserStatus,
      defaultValues,
      validationSchema,
      settingsForm,
      familyMemberIdRef,
      removeFamilyMemberModal,
      generalSettingsLoading,
      openRemoveFamilyMemberModal,
      refreshDefaultValues,
      deleteFamilyMember,
      localityChanged,
      onSubmit,
    };
  },
});
</script>
