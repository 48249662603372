<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
    <div class="items-center xl:block sm:space-x-4 xl:space-x-0 2xl:space-x-4">
      <div>
        <div class="text-center">
          <EnvelopeOpenIcon class="mx-auto h-12 w-12 text-gray-400" />
          <h3 class="mt-2 font-semibold text-gray-900">
            Please verify your email
          </h3>
          <p class="mt-2 text-gray-500">
            Please check your email for a one-time verification code.
          </p>
          <OTPComponent
            class="mt-4 justify-center"
            :digit-count="4"
            @update:otp="updateOTP"></OTPComponent>
          <p
            class="mt-4 text-sm text-red-600 dark:text-red-500"
            v-if="invalidCode">
            {{ invalidCodeText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { EnvelopeOpenIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '@/store/auth.store';
import OTPComponent from '@/components/forms/OTPInput.vue';
import { useUserStore } from '@/store/user.store';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'VerifyEmailComponent',
  components: { OTPComponent, EnvelopeOpenIcon },
  setup() {
    const invalidCode = ref(false);
    const invalidCodeText = ref('');
    const authStore = useAuthStore();
    const userStore = useUserStore();

    const updateOTP = (code: string) => {
      authStore
        .validateCode(code)
        .then(async () => {
          invalidCode.value = false;
          await userStore.getDetails();
        })
        .catch(async (error: unknown) => {
          if (error instanceof AxiosError) {
            invalidCode.value = true;
            if (error.response && error.response.data) {
              if (error.response.data.message == 'Expired OTP') {
                invalidCodeText.value = 'Code expired. We sent you a new one';
                await authStore.requestCode();
              } else {
                invalidCodeText.value = 'Incorrect code';
              }
            }
          }
        });
    };

    return {
      invalidCode,
      invalidCodeText,
      updateOTP,
    };
  },
});
</script>
