import { createRouter, createWebHistory } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import RegistrationView from '@/views/RegistrationView.vue';
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import SettingsView from '@/views/SettingsView.vue';
import PlansView from '@/views/PlansView.vue';
import ReportsView from '@/views/ReportsView.vue';
import { authGuard } from '@/guards/auth.guard';
import { useAuthStore } from '@/store/auth.store';
import ReportView from '@/views/ReportView.vue';
import ForgotPasswordView from '@/views/ForgotPasswordView.vue';

const routes = [
  {
    path: '/',
    name: '',
    component: HomeView,
    meta: {
      title: 'PrivacyZen',
      transition: 'fade',
      requiresAuth: true,
    },
    beforeEnter: () => {
      window.location.href = '/home';
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationView,
    meta: {
      title: 'Register | PrivacyZen',
      transition: 'fade',
    },
  },
  {
    path: '/forgot-password/:token?',
    name: 'forgot-password',
    component: ForgotPasswordView,
    meta: {
      title: 'Forgot Password | PrivacyZen',
      transition: 'fade',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Log In | PrivacyZen',
      transition: 'fade',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home | PrivacyZen', requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { title: 'Profiles | PrivacyZen', requiresAuth: true },
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView,
    meta: { title: 'Reports | PrivacyZen', requiresAuth: true },
  },
  {
    path: '/reports/:id',
    name: 'report',
    component: ReportView,
    meta: { title: 'Report | PrivacyZen', requiresAuth: true },
  },
  {
    path: '/premium',
    name: 'premium',
    component: PlansView,
    meta: { title: 'Premium | PrivacyZen', requiresAuth: true },
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext,
      ) {
        const authStore = useAuthStore();
        authStore.logout();
        next('/login');
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authGuard(to, from, next);
  } else {
    next();
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title as string;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode!.removeChild(el),
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  (nearestWithMeta.meta.metaTags as any)
    .map((tagDef: any) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
