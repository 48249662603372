<template>
  <div
    id="alert-border-3"
    class="flex p-4 mb-4 border-t-4"
    :class="{
      'border-green-300 bg-green-50 text-green-800': variant === 'success',
      'border-red-300 bg-red-50 text-red-800': variant === 'danger',
      'border-yellow-300 bg-yellow-50 text-yellow-800': variant === 'warning',
      'border-blue-300 bg-blue-50 text-blue-800': variant === 'info',
    }"
    role="alert">
    <slot name="icon"></slot>
    <div class="ml-3 text-sm font-medium">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: 'success', // Default variant is 'success'
      validator: (variant) =>
        ['success', 'danger', 'warning', 'info'].includes(variant), // Validates the variant prop
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
