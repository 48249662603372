import apiClient from '@/utils/http-common';
import { defineStore } from 'pinia';

export const useStripeStore = defineStore({
  id: 'stripe',
  state: () => ({}),
  actions: {
    async getConfig() {
      const response = await apiClient.get('/stripe/config');
      return response.data;
    },
    async getCustomer() {
      const response = await apiClient.get('/stripe/customer');
      return response.data;
    },
    async setDefaultPaymentMethod(paymentMethodId: string) {
      const response = await apiClient.post('/stripe/default-payment-method', {
        paymentMethodId,
      });
      return response.data;
    },
    async attachPaymentMethod(paymentMethodId: string) {
      return await apiClient.post('/stripe/attach-payment-method', {
        paymentMethodId,
      });
    },
    async detachPaymentMethod(paymentMethodId: string) {
      return await apiClient.post('/stripe/detach-payment-method', {
        paymentMethodId,
      });
    },
    async upcomingInvoice({
      planName,
      interval,
    }: {
      planName: string;
      interval: string;
    }) {
      const response = await apiClient.post('/stripe/upcoming-invoice', {
        planName,
        interval,
      });
      return response.data;
    },
    async createSubscription({
      planName,
      interval,
    }: {
      planName: string;
      interval: string;
    }) {
      const response = await apiClient.post('/stripe/create-subscription', {
        planName,
        interval,
      });
      return response.data;
    },
    async updateSubscription({
      planName,
      interval,
    }: {
      planName: string;
      interval: string;
    }) {
      const response = await apiClient.post('/stripe/update-subscription', {
        planName,
        interval,
      });
      return response.data;
    },
    async cancelSubscription() {
      const response = await apiClient.post('/stripe/cancel-subscription');
      return response.data;
    },
    async createCustomerPortal() {
      const response = await apiClient.post(
        '/stripe/create-customer-portal-session',
      );
      window.location.href = response.data.sessionUrl;
    },
    async getPaymentMethods() {
      const response = await apiClient.get('stripe/list-payment-methods');
      return response.data;
    },
  },
});
