<template>
  <div
    class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2-700 sm:p-6">
    <div
      class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
      <div>
        <div class="flex items-center justify-between mb-4">
          <h3 class="text-xl font-bold leading-none text-gray-900">
            Relatives
          </h3>
          <a href="#" class="text-sm font-medium text-blue-600 hover:underline">
            BETA
          </a>
        </div>
        <p class="text-gray-500">
          <template v-if="predictedRelatives.length > 0">
            We identified potential family members during your scan.
            <a
              href="#"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
              Learn more about including them in future scans and removals.
            </a>
          </template>
          <template v-else>
            We're currently working on identifying possible relatives who are
            exposed. Check back in a few hours.
          </template>
        </p>
        <div class="mt-4">
          <ul class="grid gap-2.5 gap- grid-cols-1 grid-cols-2">
            <template v-for="(_, index) in 4">
              <li v-if="!predictedRelatives[index]" :key="'_' + index">
                <a
                  class="flex items-center min-w-48 px-2 py-1 md:px-3 md:py-2.5 flex-shrink-0 bg-gray-100 rounded-xl animate-pulse">
                  <div
                    class="h-4 flex items-center justify-center flex-shrink-0 overflow-hidden align-middle"></div>
                </a>
              </li>
              <li v-else :key="index">
                <a
                  class="flex items-center min-w-48 px-2 py-1 md:px-3 md:py-2.5 flex-shrink-0 border-.5 bg-opacity-50 rounded-xl cursor-pointer"
                  :class="
                    predictedRelatives[index].protected
                      ? 'bg-green-100 border-green-200'
                      : 'bg-orange-100 border-orange-200'
                  "
                  @click="redirectToFamilyMembers">
                  <div
                    class="flex items-center justify-center flex-shrink-0 overflow-hidden align-middle">
                    <component
                      :is="
                        predictedRelatives[index].protected
                          ? 'LockClosedIcon'
                          : 'ShieldExclamationIcon'
                      "
                      class="w-4 h-4"
                      :class="
                        predictedRelatives[index].protected
                          ? 'text-green-500'
                          : 'text-orange-500'
                      " />
                  </div>
                  <span
                    class="ml-2 text-13px line-clamp-1 text-gray-900"
                    :title="predictedRelatives[index].relative.fullName">
                    {{ predictedRelatives[index].relative.fullName }}
                  </span>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <UpsellModal ref="upsellModal" />
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/store/user.store';
import { ShieldExclamationIcon, LockClosedIcon } from '@heroicons/vue/24/solid';
import UpsellModal from './UpsellModal.vue';
import router from '@/router';

export default defineComponent({
  name: 'RelativesComponent',
  inheritAttrs: false,
  components: { ShieldExclamationIcon, LockClosedIcon, UpsellModal },
  computed: {
    ...mapStores(useUserStore),
  },
  setup() {
    const userStore = useUserStore();
    const predictedRelatives = ref([] as any);
    const upsellModal = ref();

    const getFamilyMembers = async () => {
      const familyMembers = [];
      const responseData = await userStore.getFamilyMembers();

      for (const familyMember of responseData) {
        familyMembers.push(
          `${familyMember['firstName'].toLowerCase()} ${familyMember[
            'lastName'
          ].toLowerCase()}`,
        );
      }

      return familyMembers;
    };

    const getPredictedRelatives = async () => {
      const familyMembers = await getFamilyMembers();
      const responseData = await userStore.getPredictedRelatives();

      for (const predictedRelative of responseData) {
        predictedRelatives.value.push({
          protected: familyMembers.includes(
            predictedRelative.fullName.toLowerCase(),
          ),
          relative: predictedRelative,
        });
      }
    };

    const openUpsellModal = () => {
      if (upsellModal.value) {
        upsellModal.value.openModal();
      }
    };

    const redirectToFamilyMembers = () => {
      if (
        userStore.subscription.active &&
        userStore.subscription.planName == 'Family'
      ) {
        router.push('settings');
      } else {
        openUpsellModal();
      }
    };

    onMounted(() => {
      getPredictedRelatives();
    });

    return {
      upsellModal,
      predictedRelatives,
      openUpsellModal,
      redirectToFamilyMembers,
    };
  },
});
</script>
